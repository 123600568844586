import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { IconButton } from "@mui/material";
import NotificationPop, {
  OverLayContainer,
} from "../notifications/NotificationPop";
import AccountPopUp from "./AccountPopUp";

const NotificationBar = () => {
  const [openNotifications, setOpenNotifications] = useState(false);

  useEffect(() => {
    if (openNotifications) {
      document.body.style.overflow = "hidden";
    }
    return () => (document.body.style.overflow = "unset");
  }, [openNotifications]);

  return (
    <FlexContainer>
      {openNotifications && (
        <OverLayContainer onClick={() => setOpenNotifications(false)} />
      )}
      <NotificationPop
        open={openNotifications}
        setOpen={setOpenNotifications}
      />
      <IconButton
        onClick={() => {
          setOpenNotifications((value) => !value);
        }}
      >
        <NotificationsOutlinedIcon size="small" style={{ color: "#ACACAC" }} />
      </IconButton>
      <AccountPopUp />
    </FlexContainer>
  );
};

export default NotificationBar;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem 2rem;
  justify-content: flex-end;
  @media (max-width: 768px) {
    display: none;
  }
`;
