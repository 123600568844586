import { useState, Fragment } from "react";
import Select from "react-select";
import styled from "styled-components";
import { Heading, Text } from "../../components/shared/Typography/Typo";
import { styled as styles } from "@mui/system";
import SwitchUnstyled, {
  switchUnstyledClasses,
} from "@mui/base/SwitchUnstyled";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import NotificationsOffOutlinedIcon from "@mui/icons-material/NotificationsOffOutlined";
import VibrationIcon from "@mui/icons-material/Vibration";

const Root = styles("span")`
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 32px;
  height: 20px;
  margin: 10px;
  cursor: pointer;

  &.${switchUnstyledClasses.disabled} {
    opacity: 0.4;
    cursor: not-allowed;
  }

  & .${switchUnstyledClasses.track} {
    background: #b3c3d3;
    border-radius: 10px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  & .${switchUnstyledClasses.thumb} {
    display: block;
    width: 14px;
    height: 14px;
    top: 3px;
    left: 3px;
    border-radius: 16px;
    background-color: #394673;
    position: relative;
    transition: all 200ms ease;
  }

  &.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
  }

  &.${switchUnstyledClasses.checked} {
    .${switchUnstyledClasses.thumb} {
      left: 14px;
      top: 3px;
      background-color: #fff;
    }

    .${switchUnstyledClasses.track} {
      background: #394673;
    }
  }

  & .${switchUnstyledClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }
`;

const Content = () => {
  const [blockchain, setBlockchain] = useState();
  const [notification, setNotification] = useState();

  const blockchains = [
    { value: "ETH", label: "Ethereum" },
    { value: "STLR", label: "Stellar" },
  ];

  const notifications = [
    { value: "ring", label: "Ring", icon: <NotificationsNoneOutlinedIcon /> },
    {
      value: "silent",
      label: "Silent",
      icon: <NotificationsOffOutlinedIcon />,
    },
    { value: "vibrate", label: "Vibrate", icon: <VibrationIcon /> },
  ];

  const label = { componentsProps: { input: { "aria-label": "Demo switch" } } };

  return (
    <Fragment>
      <InnerContainer>
        <ColumnContainer>
          <Label>Choose Blockchain</Label>
          <Select
            defaultValue={{ value: "ETH", label: "Ethereum" }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                outline: "none",
                text: "orangered",
                primary25: "#2f3a6039",
                primary: " #2f3a60a7",
                neutral0: "#eaeaf3",
                boxShadow: "none",
              },
            })}
            options={blockchains}
            value={blockchain}
            onChange={setBlockchain}
          />
        </ColumnContainer>
        <ColumnContainer>
          <Label>Notifications</Label>
          <Select
            defaultValue={{
              value: "ring",
              label: "Ring",
              icon: <NotificationsNoneOutlinedIcon />,
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                outline: "none",
                text: "orangered",
                primary25: "#2f3a6039",
                primary: " #2f3a60a7",
                neutral0: "#eaeaf3",
                boxShadow: "none",
              },
            })}
            options={notifications}
            value={notification}
            onChange={setNotification}
            getOptionLabel={(e) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                {e.icon}
                <span style={{ marginLeft: 5 }}>{e.label}</span>
              </div>
            )}
          />
        </ColumnContainer>
        <ColumnContainer>
          <Label>Preference</Label>
          <PreferenceCard>
            <Text primary>Profile Visibility</Text>
            <Text primary>Public</Text>
            <SwitchUnstyled component={Root} {...label} defaultChecked />
          </PreferenceCard>
        </ColumnContainer>
        <ColumnContainer>
          <Label>Request credential change</Label>
          <ChangeButton>Change password or email</ChangeButton>
        </ColumnContainer>
      </InnerContainer>
    </Fragment>
  );
};

export default Content;

const InnerContainer = styled.div`
  display: flex;
  margin: 1rem 2rem;
  flex-direction: column;
  gap: 1rem;
  background: rgba(234, 234, 243, 0.32);
  border: 0.75px solid rgba(57, 70, 115, 0.3);
  box-sizing: border-box;
  border-radius: 10px;
  padding: 3rem 1.5rem;
  @media (max-width: 1368px) {
    padding: 0.25rem;
    margin: 0.75rem;
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  margin: 0.2rem 1.5rem;
  gap: 0.7rem;
  flex-direction: column;
`;

const Label = styled.label`
  color: #2f3a60;
  font-weight: 600;
  font-size: 16px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const ChangeButton = styled.button`
  border-radius: 5px;
  margin-bottom: 2rem;
  font-weight: 500;
  border: none;
  outline: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  padding: 0.5rem 1rem;
  background-color: #2f3a60;
  color: #ffffff;
  text-transform: inherit;
  cursor: pointer;
`;

const PreferenceCard = styled.div`
  display: flex;
  justify-content: space-between;
  background: #eaeaf3;
  align-items: center;
  border-radius: 5px;
  padding: 0.5rem 2rem;

  @media (max-width: 1368px) {
    padding: 0.5rem 1rem;
  }
`;
