import React from "react";
import styled from "styled-components";
import { SubHeading, Text } from "../Typography/Typo";
import NotificationBox from "./NotificationBox";
import Image from "../../../assets/images/text logo.png";

const NotificationPop = ({ open, setOpen }) => {
  return (
    <Container open={open}>
      <ColumnContainer>
        <SubHeading
          style={{
            display: "webkit-box",
            WebkitLineClamp: "3",
            WebkitBoxOrient: "vertical",
          }}
          primary
        >
          Notifications
        </SubHeading>
        <Text color="#808080b2" small>
          View all your recent notifications
        </Text>
      </ColumnContainer>
      <Line />

      <ScrollContainer>
        <ColumnContainer>
          <NotificationBox
            image={Image}
            title="Nftfluet and 16 other merchants has accepted and verified your KYC"
            time="2 hours ago"
          />
          <NotificationBox
            image={Image}
            title="Nftfluet and 16 other merchants has accepted and verified your KYC"
            time="2 hours ago"
          />
          <NotificationBox
            image={Image}
            title="Nftfluet and 16 other merchants has accepted and verified your KYC"
            time="2 hours ago"
          />
          <NotificationBox
            image={Image}
            title="Nftfluet and 16 other merchants has accepted and verified your KYC"
            time="2 hours ago"
          />
          <NotificationBox
            image={Image}
            title="Nftfluet and 16 other merchants has accepted and verified your KYC"
            time="2 hours ago"
          />
          <NotificationBox
            image={Image}
            title="Nftfluet and 16 other merchants has accepted and verified your KYC"
            time="2 hours ago"
          />
          <NotificationBox
            image={Image}
            title="Nftfluet and 16 other merchants has accepted and verified your KYC"
            time="2 hours ago"
          />
          <NotificationBox
            image={Image}
            title="Nftfluet and 16 other merchants has accepted and verified your KYC"
            time="2 hours ago"
          />
          <NotificationBox
            image={Image}
            title="Nftfluet and 16 other merchants has accepted and verified your KYC"
            time="2 hours ago"
          />
          <NotificationBox
            image={Image}
            title="Nftfluet and 16 other merchants has accepted and verified your KYC"
            time="2 hours ago"
          />
          <NotificationBox
            image={Image}
            title="Nftfluet and 16 other merchants has accepted and verified your KYC"
            time="2 hours ago"
          />
          <NotificationBox
            image={Image}
            title="Nftfluet and 16 other merchants has accepted and verified your KYC"
            time="2 hours ago"
          />
          <NotificationBox
            image={Image}
            title="Nftfluet and 16 other merchants has accepted and verified your KYC"
            time="2 hours ago"
          />
        </ColumnContainer>
      </ScrollContainer>
    </Container>
  );
};

export default NotificationPop;

const Container = styled.div`
  padding: 1.5rem;
  display: ${(props) => (props.open ? "flex" : "none")};
  flex-direction: column;
  position: absolute;
  top: 6%;
  right: 6%;
  z-index: 999;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  width: 25rem;
  border-radius: 5px;
  background-color: #fff;
  @media (max-width: 1368px) {
    top: 4%;
    right: 6%;
  }
`;

export const OverLayContainer = styled.div`
  position: absolute;
  z-index: 999;
  top: 0;
  width: 100%;
  height: 100vh;
  left: 0;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Line = styled.span`
  background-color: rgba(34, 34, 34, 0.2);
  height: 0.7px;
  margin-bottom: 2rem;
  margin-top: 0.6rem;
`;

const ScrollContainer = styled.div`
  max-height: 80vh;
  padding-right: 0.5rem;
  height: 75vh;
  overflow: auto;
`;
