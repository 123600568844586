import styled from "styled-components";
import { Heading } from "../../components/shared/Typography/Typo";
import Row from "../../components/shared/core/Card/Row";
import CorporateVerifiedCard from "../../components/shared/core/Card/CorporateVerifiedCard";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useSelector } from "react-redux";
import { LoadingRow } from "../../pages/Dashboard/Dashboard";
import { useEffect, useState } from "react";
import store from "../../redux/reducers";
import { ADD_USER_DETAILS } from "../../redux/constants/ActionTypes";
import { decodeUser } from "../../api/Api";
import MoreFieldsModal from "../../components/shared/Modal/MoreFieldsModal";
import { CustomButton } from "../../components/shared/fields/Button/CustomButton";

const UserDetails = ({ data }) => {
  let componentMounted = true;
  const currentUser = useSelector((state) => state.currentUser);
  const merchantData = currentUser.userOwnedData;
  const [loading, setLoading] = useState(false);
  const [moreData, setMoreData] = useState([]);
  const [openMoreFieldsModal, setOpenMoreFieldsModal] = useState(false);

  const retrieveUser = async () => {
    const token = localStorage.getItem("niftoken");
    setLoading(true);
    try {
      const retrieveUserDetails = await decodeUser({
        token: token,
      });
      if (componentMounted) {
        if (retrieveUserDetails && retrieveUserDetails.data) {
          store.dispatch({
            type: ADD_USER_DETAILS,
            payload: retrieveUserDetails.data.data[0],
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (localStorage.getItem("niftoken")) {
      retrieveUser();
      return () => {
        componentMounted = false;
      };
    }
  }, []);

  return (
    <Container>
      <FlexContainer>
        <Heading color="#394673">Corporate Details</Heading>
        {data && !loading ? (
          <>
            <MoreFieldsModal
              openMoreFieldsModal={openMoreFieldsModal}
              setOpenMoreFieldsModal={setOpenMoreFieldsModal}
              data={moreData}
              type="merchant"
            />
            <UserDetailsContainer>
              <Row rowName="Alias" rowData={currentUser?.alias} />
              {/* <Row rowName="Public Key" rowData={currentUser?.publicKey} /> */}
              {merchantData?.companyName?.data ? (
                <Row
                  rowName="Merchant Name"
                  rowData={merchantData?.companyName?.data}
                />
              ) : null}
              {merchantData?.city?.data || merchantData?.country?.data ? (
                <Row
                  rowName="Address"
                  rowData={`${merchantData?.city?.data || ""} ${
                    merchantData?.country?.data || ""
                  }`}
                />
              ) : null}
              {merchantData?.email?.data ? (
                <Row
                  rowName="Email Address"
                  rowData={merchantData?.email?.data}
                />
              ) : null}
              {merchantData?.brcNo?.data ? (
                <Row rowName="BRC" rowData={merchantData?.brcNo?.data} />
              ) : null}
              {merchantData?.phoneNumber?.data ? (
                <Row
                  rowName="Phone Number"
                  rowData={merchantData?.phoneNumber?.data}
                />
              ) : null}

              <CustomButton
                tab
                text="View More"
                onclick={() => {
                  setMoreData(merchantData);
                  setOpenMoreFieldsModal(true);
                }}
              />
            </UserDetailsContainer>
          </>
        ) : (
          <>
            <LoadingRow />
            <LoadingRow />
            <LoadingRow />
            <LoadingRow />
          </>
        )}
      </FlexContainer>
      <FlexContainer>
        <Heading color="#394673">
          Verified By
          <CheckCircleIcon
            fontSize="medium"
            style={{
              color: "23C99E",
              marginLeft: "0.5rem",
            }}
          />
        </Heading>
        {loading ? (
          <>
            <LoadingRow />
          </>
        ) : (
          <VerifiedByContainer>
            <CorporateVerifiedCard merchantName="Nexentry" status="Verified" />
          </VerifiedByContainer>
        )}
      </FlexContainer>
    </Container>
  );
};

export default UserDetails;

const Container = styled.div`
  margin: 1.8rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  @media (max-width: 1368px) {
    margin: 1rem;
    grid-template-columns: 1fr;
  }
`;

const UserDetailsContainer = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const VerifiedByContainer = styled.div`
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
