import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import Logo from "../../../assets/images/logo 1.png";
import { CustomButton } from "../fields/Button/CustomButton";
import AccountPopUp from "./AccountPopUp";

const MainNavigation = ({ isLoggedIn }) => {
  const history = useHistory();
  return (
    <Container>
      <LogoContainer src={Logo} />
      <NavContainer>
        <NavLink to="/dashboard">Dashboard</NavLink>
        <NavLink to="/about">About</NavLink>
        <NavLink to="/contact">Contact</NavLink>
        {isLoggedIn ? (
          <AccountPopUp />
        ) : (
          <CustomButton
            onclick={() => {
              history.push("/login");
            }}
            text="Sign In"
            login
            loginIcon
            loginClass
          />
        )}
      </NavContainer>
    </Container>
  );
};

export default MainNavigation;

export const Container = styled.div`
  background: ${(props) =>
    props.addBackground && "linear-gradient(270deg, #1C2338 0%, #2F3A60 100%)"};
  padding: 1rem;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.75px solid #9292924b;
  @media (max-width: 768px) {
    padding: 0.75rem;
  }
`;

const NavContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const NavLink = styled(Link)`
  text-decoration: none;
  font-family: "Open Sans", sans-serif;
  font-size: 1.125rem;
  color: #fff;
  font-weight: 400;
  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;

export const LogoContainer = styled.img`
  width: 14rem;
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
  height: auto;
  @media (max-width: 768px) {
    width: 10rem;
  }
`;
