import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Row from "../core/Card/Row";
import styled from "styled-components";
import {
  MORE_MERCHANT_FIELDS,
  MORE_USER_FIELDS,
} from "../../../utils/moreFields";
import { Text } from "../Typography/Typo";
import AnonymousImage from "../../../assets/images/anonymous.png";

const MoreFieldsModal = ({
  openMoreFieldsModal,
  setOpenMoreFieldsModal,
  data,
  type,
}) => {
  const [open, setOpen] = useState(false);
  const [moreData, setMoreData] = useState({});

  const handleClose = () => {
    setOpenMoreFieldsModal(false);
  };

  const openImageInNewTab = (url) => {
    window.open(url);
  };

  useEffect(() => {
    setOpen(openMoreFieldsModal);
  }, [openMoreFieldsModal]);

  useEffect(() => {
    setMoreData(data);
  }, [data]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>
        <h3>More Information</h3>
      </DialogTitle>
      <DialogContent>
        <MoreDetailsContainer>
          {type === "user"
            ? MORE_USER_FIELDS.map((val) => {
                if (
                  val.name !== "brcImage" &&
                  val.name !== "profileImage" &&
                  val.name !== "logo" &&
                  val.name !== "birthCertificate" &&
                  val.name !== "drivingLicense" &&
                  val.name !== "marriageCertificate" &&
                  val.name !== "signature" &&
                  val.name !== "managerSignature" &&
                  val.name !== "passportImage" &&
                  val.name !== "addressImage"
                ) {
                  return (
                    <Row
                      rowName={val.label}
                      rowData={moreData[val.name]}
                      style={{ marginTop: "1.5rem" }}
                    />
                  );
                } else {
                  if (val.name in moreData) {
                    return (
                      <>
                        <h4 style={{ marginTop: "1.5rem" }}>{val.label}</h4>
                        <FlexContainer>
                          {moreData[val.name].length > 0 ? (
                            moreData[val.name]?.map((img, index) => {
                              return (
                                <img
                                  src={img || AnonymousImage}
                                  alt={`${val.name}-${index}`}
                                  style={{
                                    cursor: "pointer",
                                    marginBottom: "1em",
                                    // maxWidth: "fit-content",
                                  }}
                                  onClick={() => openImageInNewTab(img)}
                                />
                              );
                            })
                          ) : (
                            <Text color="#2F3A60">N/A</Text>
                          )}
                        </FlexContainer>
                      </>
                    );
                  }
                }
              })
            : MORE_MERCHANT_FIELDS.map((val) => {
                if (
                  val.name !== "brcImage" &&
                  val.name !== "profileImage" &&
                  val.name !== "logo" &&
                  val.name !== "birthCertificate" &&
                  val.name !== "drivingLicense" &&
                  val.name !== "marriageCertificate" &&
                  val.name !== "signature" &&
                  val.name !== "managerSignature" &&
                  val.name !== "passportImage" &&
                  val.name !== "addressImage"
                ) {
                  return (
                    <Row
                      rowName={val.label}
                      rowData={moreData[val.name]?.data}
                      style={{ marginTop: "1.5rem" }}
                    />
                  );
                } else {
                  if (val.name in moreData) {
                    return (
                      <>
                        <h4 style={{ marginTop: "1.5rem" }}>{val.label}</h4>
                        <FlexContainer>
                          {moreData[val.name].length > 0 ? (
                            moreData[val.name]?.map((img, index) => {
                              return (
                                <img
                                  src={img.data || img || AnonymousImage}
                                  alt={`${val.name}-${index}`}
                                  style={{
                                    cursor: "pointer",
                                    marginBottom: "1em",
                                    // maxWidth: "fit-content",
                                  }}
                                  onClick={() =>
                                    openImageInNewTab(img.data || img)
                                  }
                                />
                              );
                            })
                          ) : (
                            <Text color="#2F3A60">N/A</Text>
                          )}
                        </FlexContainer>
                      </>
                    );
                  }
                }
              })}
        </MoreDetailsContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default MoreFieldsModal;

const MoreDetailsContainer = styled.div`
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
