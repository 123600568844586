import styled from "styled-components";
import BgOne from "../../assets/images/bg-1.jpg";
import BgTwo from "../../assets/images/BGTwo.png";
import { useStateValue } from "../../context/StateProvider";
import { LoginBanner } from "./LoginBanner";
import { LoginComponent } from "./LoginComponent";
import { GridContainer } from "./LoginPageStyles";

const Login = () => {
  const [{ currentUser }, dispatch] = useStateValue();
  return (
    // <Container image={userType === 1 ? BgTwo : BgOne}>
    <Container image={BgTwo}>
      <GridContainer>
        <LoginBanner />
        <LoginComponent
          isMerchant={
            currentUser?.length > 0 && currentUser.data.type === "merchant"
          }
        />
      </GridContainer>
      <OverLay />
    </Container>
  );
};

export default Login;

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 100vh;
  background-size: cover;
  backface-visibility: hidden;
  background-image: url(${(props) => props.image});
  @media (max-width: 768px) {
    height: auto;
  }
`;

const OverLay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    #20273e 1.81%,
    rgba(28, 35, 56, 0.29) 98.77%
  );
  @media (max-width: 768px) {
    background: linear-gradient(
      180deg,
      #20273e 1.81%,
      rgba(28, 35, 56, 0.29) 98.77%
    );
  }
`;
