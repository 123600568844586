import React from "react";
import Button from "@mui/material/Button";
import { LoginIcon } from "./LoginIcon";
import "../../../../assets/css/CustomButton.css";

export const CustomButton = (props) => {
  if (props.login)
    return (
      <Button
        style={props.style}
        onClick={props.onclick}
        type={props.type}
        disabled={props.disabled}
        form={props.form}
        startIcon={props.startIcon}
        className={
          props.loginClass
            ? "primaryLogin"
            : props.secondaryLoginClass
            ? "secondaryLogin"
            : props.wide
            ? "wide"
            : "regular"
        }
        variant={props.outlined && "outlined"}
        endIcon={props.loginIcon ? <LoginIcon /> : props.icon}
      >
        {props.text}
      </Button>
    );
  else if (props.tab) {
    return (
      <Button
        onClick={props.onclick}
        disabled={props.disabled}
        startIcon={props.startIcon}
        className={props.className ? props.className : "primaryTab"}
        fullWidth
        type={props.type}
        endIcon={props.icon}
      >
        {props.text}
      </Button>
    );
  }
};
