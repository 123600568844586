import { useEffect, useState } from "react";
import styled from "styled-components";
import { Heading, Text } from "../shared/Typography/Typo";
import Row from "../shared/core/Card/Row";
import Table from "../shared/Table/Table";
import OptionsSwitch from "./OptionsSwitch";
import { Avatar } from "@mui/material";
import { useSelector } from "react-redux";
import * as encryption from "../../utils/encryption";
import { CustomButton } from "../shared/fields/Button/CustomButton";
import { verifyUser, retrieveMerchantsVerifications } from "../../api/Api";
import { useSnackbar } from "notistack";
import { getUserSession } from "../../services/UserManagement";
import { useStateValue } from "../../context/StateProvider";
import { LoadingRow } from "../../pages/Dashboard/Dashboard";
import MoreFieldsModal from "../shared/Modal/MoreFieldsModal";
import AnonymousImage from "../../assets/images/anonymous.png";

// USERS AND MERCHANTS
export const tbodyData = [
  {
    id: "1",
    items: ["Individual KYC Onboarding", "Jan 15 10.23AM", "23.12"],
  },
  {
    id: "2",
    items: ["Individual KYC Onboarding", "Jan 15 10.23AM", "23.12"],
  },
  {
    id: "3",
    items: ["Individual KYC Onboarding", "Jan 15 10.23AM", "23.12"],
  },
  {
    id: "3",
    items: ["Individual KYC Onboarding", "Jan 15 10.23AM", "23.12"],
  },
  {
    id: "3",
    items: ["Individual KYC Onboarding", "Jan 15 10.23AM", "23.12"],
  },
  {
    id: "3",
    items: ["Individual KYC Onboarding", "Jan 15 10.23AM", "23.12"],
  },
  {
    id: "3",
    items: ["Individual KYC Onboarding", "Jan 15 10.23AM", "23.12"],
  },
  {
    id: "3",
    items: ["Individual KYC Onboarding", "Jan 15 10.23AM", "23.12"],
  },
  {
    id: "3",
    items: ["Individual KYC Onboarding", "Jan 15 10.23AM", "23.12"],
  },
  {
    id: "3",
    items: ["Individual KYC Onboarding", "Jan 15 10.23AM", "23.12"],
  },
  {
    id: "3",
    items: ["Individual KYC Onboarding", "Jan 15 10.23AM", "23.12"],
  },
  {
    id: "3",
    items: ["Individual KYC Onboarding", "Jan 15 10.23AM", "23.12"],
  },
  {
    id: "3",
    items: ["Individual KYC Onboarding", "Jan 15 10.23AM", "23.12"],
  },
  {
    id: "3",
    items: ["Individual KYC Onboarding", "Jan 15 10.23AM", "23.12"],
  },
  {
    id: "3",
    items: ["Individual KYC Onboarding", "Jan 15 10.23AM", "23.12"],
  },
];
const theadData = ["Description", "Date", "Credits Earned $"];

const ProfileView = ({ data, type, onPress, id, isSelected, publicKey }) => {
  const [{ users }, dispatch] = useStateValue();
  const currentUser = useSelector((state) => state.currentUser);
  const [isVerified, setIsVerified] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const userPublicKey = currentUser.publicKey;
  const [loading, setLoading] = useState(false);
  const [decryptedInteractionData, setDecryptedInteractionData] = useState({
    profileImage: "",
    firstName: "",
    lastName: "",
    city: "",
    country: "",
    nic: "",
    phoneNumber: "",
    email: "",
    brcNo: "",
  });
  const [moreData, setMoreData] = useState([]);
  const [openMoreFieldsModal, setOpenMoreFieldsModal] = useState(false);

  const decryptData = () => {
    let decryptedData = {};
    setLoading(true);
    if (data?.requestedBy === userPublicKey) {
      // toData
      const toData = data?.toData;
      delete toData.updatedAt;
      delete toData.createdAt;
      for (let [key, value] of Object.entries(toData)) {
        if (
          key !== "brcImage" &&
          key !== "profileImage" &&
          key !== "logo" &&
          key !== "birthCertificate" &&
          key !== "drivingLicense" &&
          key !== "marriageCertificate" &&
          key !== "signature" &&
          key !== "managerSignature" &&
          key !== "passportImage" &&
          key !== "addressImage"
        ) {
          // Asymmetric decryption
          decryptedData[key] = encryption.asymmetricDecryption(
            value,
            currentUser.secretKey
          );
        } else {
          const tempArr = [];
          toData[key]?.map((item, key) => {
            const enc = encryption.asymmetricDecryption(
              item,
              currentUser.secretKey
            );
            tempArr.push(enc);
          });
          decryptedData[key] = tempArr;
        }
      }
    } else if (data?.requestedTo === userPublicKey) {
      // fromData
      const fromData = data?.fromData;
      delete fromData.updatedAt;
      delete fromData.createdAt;
      for (let [key, value] of Object.entries(fromData)) {
        if (
          key !== "brcImage" &&
          key !== "profileImage" &&
          key !== "logo" &&
          key !== "birthCertificate" &&
          key !== "drivingLicense" &&
          key !== "marriageCertificate" &&
          key !== "signature" &&
          key !== "managerSignature" &&
          key !== "passportImage" &&
          key !== "addressImage"
        ) {
          // Asymmetric decryption
          decryptedData[key] = encryption.asymmetricDecryption(
            value,
            currentUser.secretKey
          );
        } else {
          const tempArr = [];
          fromData[key]?.map((item, key) => {
            const enc = encryption.asymmetricDecryption(
              item,
              currentUser.secretKey
            );
            tempArr.push(enc);
          });
          decryptedData[key] = tempArr;
        }
      }
    }
    setDecryptedInteractionData(decryptedData);
    setLoading(false);
  };

  const verifyUserAPI = async () => {
    try {
      const response = await verifyUser({
        token: localStorage.niftoken,
        body: {
          userPk: data?.publicKey,
          userAlias: data?.alias,
          merchantPk: currentUser.publicKey,
          merchantAlias: currentUser.alias,
        },
      });
      if (response.data?.code === 201) {
        setIsVerified(true);
        enqueueSnackbar(`Successfully Verified`, {
          variant: "success",
        });
      }
      if (response.data?.code === 302) {
        enqueueSnackbar(`User Already Verified`, {
          variant: "error",
        });
      }
    } catch (error) {
      enqueueSnackbar(`Verification Failed`, {
        variant: "error",
      });
    }
  };

  const retrieveVerifiedUsers = async () => {
    try {
      const response = await retrieveMerchantsVerifications({
        merchantPk: currentUser.publicKey,
      });
      if (response?.data?.code === 200) {
        if (
          response?.data?.data.find((item) => item?.userAlias === users?.alias)
        ) {
          setIsVerified(true);
        } else {
          setIsVerified(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    retrieveVerifiedUsers();
  }, [users]);

  useEffect(() => {
    decryptData();
  }, [id]);

  if (data?.action === "u2u") {
    return (
      <Container>
        {loading ? (
          <>
            <LoadingRow />
            <LoadingRow />
            <LoadingRow />
          </>
        ) : (
          <Banner>
            <ImageWrapper
              image={decryptedInteractionData?.profileImage || AnonymousImage}
            />
            <ContentWrapper>
              <Heading color="#394673">
                {decryptedInteractionData?.firstName ||
                decryptedInteractionData?.lastName
                  ? `${decryptedInteractionData?.firstName || ""} ${
                      decryptedInteractionData?.lastName || ""
                    }`
                  : data?.requestedBy === userPublicKey
                  ? data?.requestedToAlias
                  : data?.requestedByAlias}
              </Heading>
              <Text small color="#626262b3">
                {`${decryptedInteractionData?.city || ""} ${
                  decryptedInteractionData?.country || ""
                }`}
              </Text>
            </ContentWrapper>
          </Banner>
        )}
        {(() => {
          switch (type) {
            case 3:
              return (
                <TableOuterWrapper>
                  <TableWrapper>
                    <Table theadData={theadData} tbodyData={tbodyData} />
                  </TableWrapper>
                </TableOuterWrapper>
              );
            default:
              return (
                <>
                  {loading ? (
                    <>
                      <LoadingRow />
                      <LoadingRow />
                      <LoadingRow />
                      <LoadingRow />
                    </>
                  ) : (
                    <>
                      <MoreFieldsModal
                        openMoreFieldsModal={openMoreFieldsModal}
                        setOpenMoreFieldsModal={setOpenMoreFieldsModal}
                        data={moreData}
                        type="user"
                      />
                      <ColumnContainer>
                        <Row
                          rowName="Alias"
                          rowData={
                            data?.requestedBy === userPublicKey
                              ? data?.requestedToAlias
                              : data?.requestedByAlias
                          }
                        />
                        {/* <Row
                          rowName="Public Key"
                          rowData={
                            data?.requestedBy === userPublicKey
                              ? data?.requestedTo
                              : data?.requestedBy
                          }
                        /> */}
                        {decryptedInteractionData?.firstName ||
                        decryptedInteractionData?.lastName ? (
                          <Row
                            rowName="User Name"
                            rowData={`${
                              decryptedInteractionData?.firstName || ""
                            } ${decryptedInteractionData?.lastName || ""}`}
                          />
                        ) : null}
                        {decryptedInteractionData?.city ||
                        decryptedInteractionData?.country ? (
                          <Row
                            rowName="Address"
                            rowData={`${decryptedInteractionData?.city || ""} ${
                              decryptedInteractionData?.country || ""
                            }`}
                          />
                        ) : null}
                        {decryptedInteractionData?.email ? (
                          <Row
                            rowName="Email Address"
                            rowData={decryptedInteractionData?.email}
                          />
                        ) : null}
                        {decryptedInteractionData?.nic ? (
                          <Row
                            rowName="NIC"
                            rowData={decryptedInteractionData?.nic}
                          />
                        ) : null}
                        {decryptedInteractionData?.phoneNumber ? (
                          <Row
                            rowName="Phone Number"
                            rowData={decryptedInteractionData?.phoneNumber}
                          />
                        ) : null}
                        <CustomButton
                          tab
                          text="View More"
                          onclick={() => {
                            setMoreData(decryptedInteractionData);
                            setOpenMoreFieldsModal(true);
                          }}
                        />
                      </ColumnContainer>
                    </>
                  )}
                </>
              );
          }
        })()}
        <OptionsSwitch
          data={{
            status: 0,
            userData: {
              requestedByAlias:
                currentUser.alias === data.requestedByAlias
                  ? data.requestedToAlias
                  : data.requestedByAlias,
              requestedBy:
                currentUser.publicKey === data.requestedBy
                  ? data.requestedTo
                  : data.requestedBy,
            },
          }}
          publicKey={publicKey}
          type={type}
          onPress={onPress}
          id={id}
          isSelected={isSelected}
        />
      </Container>
    );
    // TODO: Consider u2m
  } else if (data?.action === "m2u" || data?.action === "u2m") {
    return (
      <Container>
        {(() => {
          if (currentUser.type === "merchant") {
            return (
              <>
                {loading ? (
                  <>
                    <LoadingRow />
                    <LoadingRow />
                    <LoadingRow />
                  </>
                ) : (
                  <Banner>
                    <ImageWrapper
                      image={
                        decryptedInteractionData?.profileImage || AnonymousImage
                      }
                    />
                    <ContentWrapper>
                      <Heading color="#394673">
                        {decryptedInteractionData?.firstName ||
                        decryptedInteractionData?.lastName
                          ? `${decryptedInteractionData?.firstName || ""} ${
                              decryptedInteractionData?.lastName || ""
                            }`
                          : data?.requestedBy === userPublicKey
                          ? data?.requestedToAlias
                          : data?.requestedByAlias}
                      </Heading>
                      <Text small color="#626262b3">
                        {`${decryptedInteractionData?.city || ""} ${
                          decryptedInteractionData?.country || ""
                        }`}
                      </Text>
                    </ContentWrapper>
                  </Banner>
                )}
              </>
            );
          } else {
            return (
              <>
                {loading ? (
                  <>
                    <LoadingRow />
                    <LoadingRow />
                    <LoadingRow />
                  </>
                ) : (
                  <Banner>
                    <ImageWrapper
                      image={decryptedInteractionData?.logo || AnonymousImage}
                    />
                    <ContentWrapper>
                      <Heading color="#394673">
                        {decryptedInteractionData?.companyName}
                      </Heading>
                      <Text small color="#626262b3">
                        {`${decryptedInteractionData?.city}, ${decryptedInteractionData?.country}`}
                      </Text>
                    </ContentWrapper>
                  </Banner>
                )}
              </>
            );
          }
        })()}
        {(() => {
          switch (type) {
            case 3:
              return (
                <TableOuterWrapper>
                  <TableWrapper>
                    <Table theadData={theadData} tbodyData={tbodyData} />
                  </TableWrapper>
                </TableOuterWrapper>
              );
            default:
              if (currentUser.type === "merchant") {
                return (
                  <>
                    {loading ? (
                      <>
                        <LoadingRow />
                        <LoadingRow />
                        <LoadingRow />
                        <LoadingRow />
                      </>
                    ) : (
                      <>
                        <MoreFieldsModal
                          openMoreFieldsModal={openMoreFieldsModal}
                          setOpenMoreFieldsModal={setOpenMoreFieldsModal}
                          data={moreData}
                          type="user"
                        />
                        <ColumnContainer>
                          <Row
                            rowName="Alias"
                            rowData={
                              data?.requestedBy === userPublicKey
                                ? data?.requestedToAlias
                                : data?.requestedByAlias
                            }
                          />
                          {/* <Row
                            rowName="Public Key"
                            rowData={
                              data?.requestedBy === userPublicKey
                                ? data?.requestedTo
                                : data?.requestedBy
                            }
                          /> */}
                          {decryptedInteractionData?.firstName ||
                          decryptedInteractionData?.lastName ? (
                            <Row
                              rowName="User Name"
                              rowData={`${
                                decryptedInteractionData?.firstName || ""
                              } ${decryptedInteractionData?.lastName || ""}`}
                            />
                          ) : null}
                          {decryptedInteractionData?.city ||
                          decryptedInteractionData?.country ? (
                            <Row
                              rowName="Address"
                              rowData={`${
                                decryptedInteractionData?.city || ""
                              } ${decryptedInteractionData?.country || ""}`}
                            />
                          ) : null}
                          {decryptedInteractionData?.email ? (
                            <Row
                              rowName="Email Address"
                              rowData={decryptedInteractionData?.email}
                            />
                          ) : null}
                          {decryptedInteractionData?.nic ? (
                            <Row
                              rowName="NIC"
                              rowData={decryptedInteractionData?.nic}
                            />
                          ) : null}
                          {decryptedInteractionData?.phoneNumber ? (
                            <Row
                              rowName="Phone Number"
                              rowData={decryptedInteractionData?.phoneNumber}
                            />
                          ) : null}
                          <CustomButton
                            tab
                            text="View More"
                            onclick={() => {
                              setMoreData(decryptedInteractionData);
                              setOpenMoreFieldsModal(true);
                            }}
                          />
                        </ColumnContainer>
                      </>
                    )}
                  </>
                );
              } else {
                return (
                  <>
                    {loading ? (
                      <>
                        <LoadingRow />
                        <LoadingRow />
                        <LoadingRow />
                        <LoadingRow />
                      </>
                    ) : (
                      <>
                        <MoreFieldsModal
                          openMoreFieldsModal={openMoreFieldsModal}
                          setOpenMoreFieldsModal={setOpenMoreFieldsModal}
                          data={moreData}
                          type="merchant"
                        />
                        <ColumnContainer>
                          <Row
                            rowName="Alias"
                            rowData={
                              data?.requestedBy === userPublicKey
                                ? data?.requestedToAlias
                                : data?.requestedByAlias
                            }
                          />
                          {/* <Row
                            rowName="Public Key"
                            rowData={
                              data?.requestedBy === userPublicKey
                                ? data?.requestedTo
                                : data?.requestedBy
                            }
                          /> */}
                          {decryptedInteractionData?.companyName ? (
                            <Row
                              rowName="Merchant Name"
                              rowData={decryptedInteractionData?.companyName}
                            />
                          ) : null}
                          {decryptedInteractionData?.city ||
                          decryptedInteractionData?.country ? (
                            <Row
                              rowName="Address"
                              rowData={`${
                                decryptedInteractionData?.city || ""
                              } ${decryptedInteractionData?.country || ""}`}
                            />
                          ) : null}
                          {decryptedInteractionData?.email ? (
                            <Row
                              rowName="Email Address"
                              rowData={decryptedInteractionData?.email}
                            />
                          ) : null}
                          {decryptedInteractionData?.brcNo ? (
                            <Row
                              rowName="BRC"
                              rowData={decryptedInteractionData?.brcNo}
                            />
                          ) : null}
                          {decryptedInteractionData?.phoneNumber ? (
                            <Row
                              rowName="Phone Number"
                              rowData={decryptedInteractionData?.phoneNumber}
                            />
                          ) : null}

                          <CustomButton
                            tab
                            text="View More"
                            onclick={() => {
                              setMoreData(decryptedInteractionData);
                              setOpenMoreFieldsModal(true);
                            }}
                          />
                        </ColumnContainer>
                      </>
                    )}
                  </>
                );
              }
          }
        })()}
        <OptionsSwitch
          data={{
            status: 0,
            userData: {
              requestedByAlias:
                currentUser.alias === data.requestedByAlias
                  ? data.requestedToAlias
                  : data.requestedByAlias,
              requestedBy:
                currentUser.publicKey === data.requestedBy
                  ? data.requestedTo
                  : data.requestedBy,
            },
          }}
          publicKey={publicKey}
          type={type}
          onPress={onPress}
          id={id}
          isSelected={isSelected}
        />
      </Container>
    );
  } else if (
    data?.userOwnedData === undefined ||
    data?.userOwnedData === null ||
    data === undefined ||
    data === null
  ) {
    return (
      <Text primary>
        <strong>{data?.alias}</strong> is not a verified user yet
      </Text>
    );
  } else if (data?.type === "user") {
    // For verify user button
    if (currentUser.type === "merchant") {
      return (
        <Container>
          <VerifyBanner>
            <FlexContainer>
              <Avatar variant="rounded" sx={{ width: 100, height: 100 }}>
                {data?.alias.charAt(0).toUpperCase()}
              </Avatar>
              <ContentWrapper>
                <Heading color="#394673">{data?.alias}</Heading>
                <Text small color="#626262b3">
                  {data?.publicKey}
                </Text>
              </ContentWrapper>
            </FlexContainer>
            <ContentWrapper>
              <CustomButton
                tab
                text={isVerified ? "Verified" : "Verify"}
                onclick={() => {
                  if (currentUser.userOwnedData) {
                    verifyUserAPI();
                    enqueueSnackbar(`Verified successfully`, {
                      variant: "success",
                    });
                  } else {
                    enqueueSnackbar(`Please fill your profile`, {
                      variant: "warning",
                    });
                  }
                }}
                disabled={isVerified}
              />
            </ContentWrapper>
          </VerifyBanner>
          <OptionsSwitch
            data={data}
            onPress={onPress}
            id={id}
            isSelected={isSelected}
          />
        </Container>
      );
    }
    return (
      <Container>
        <Banner>
          <Avatar variant="rounded" sx={{ width: 100, height: 100 }}>
            {data?.alias.charAt(0).toUpperCase()}
          </Avatar>
          <ContentWrapper>
            <Heading color="#394673">{data?.alias}</Heading>
            <Text small color="#626262b3">
              {data?.publicKey}
            </Text>
          </ContentWrapper>
        </Banner>
        {currentUser.alias === data?.alias ? (
          <></>
        ) : (
          <OptionsSwitch
            data={data}
            onPress={onPress}
            id={id}
            isSelected={isSelected}
          />
        )}
      </Container>
    );
  } else if (data?.type === "merchant") {
    return (
      <Container>
        <Banner>
          <Avatar
            variant="rounded"
            sx={{ width: 100, height: 100 }}
            src={data?.userOwnedData?.logo?.data}
          >
            {data?.userOwnedData?.companyName?.data?.charAt(0).toUpperCase()}
          </Avatar>
          <ContentWrapper>
            <Heading color="#394673">
              {data?.userOwnedData?.companyName?.data}
            </Heading>
            <Text small color="#626262b3">
              {data?.userOwnedData?.city?.data}
            </Text>
          </ContentWrapper>
        </Banner>
        <MoreFieldsModal
          openMoreFieldsModal={openMoreFieldsModal}
          setOpenMoreFieldsModal={setOpenMoreFieldsModal}
          data={moreData}
        />
        <ColumnContainer>
          <Row rowName="Alias" rowData={data?.alias} />
          {/* <Row rowName="Public Key" rowData={data?.publicKey} /> */}
          {data?.userOwnedData?.companyName?.data ? (
            <Row
              rowName="Merchant Name"
              rowData={data?.userOwnedData?.companyName?.data}
            />
          ) : null}
          {data?.userOwnedData?.addressL1?.data ||
          data?.userOwnedData?.addressL2?.data ||
          data?.userOwnedData?.city?.data ? (
            <Row
              rowName="Address"
              rowData={`${data?.userOwnedData?.addressL1?.data || ""} ${
                data?.userOwnedData?.addressL2?.data || ""
              } ${data?.userOwnedData?.city?.data || ""}`}
            />
          ) : null}
          {data?.userOwnedData?.email?.data ? (
            <Row
              rowName="Email Address"
              rowData={data?.userOwnedData?.email?.data}
            />
          ) : null}
          {data?.userOwnedData?.brcNo?.data ? (
            <Row rowName="BRC" rowData={data?.userOwnedData?.brcNo?.data} />
          ) : null}
          {data?.userOwnedData?.employeeCount?.data ? (
            <Row
              rowName="Employee Count"
              rowData={data?.userOwnedData?.employeeCount?.data}
            />
          ) : null}

          <CustomButton
            tab
            text="View More"
            onclick={() => {
              setMoreData(data?.userOwnedData);
              setOpenMoreFieldsModal(true);
            }}
          />
        </ColumnContainer>
        <OptionsSwitch
          data={data}
          onPress={onPress}
          id={id}
          isSelected={isSelected}
        />
      </Container>
    );
  } else {
    return (
      <>
        <Text primary>API returned an invalid object</Text>
      </>
    );
  }
};

export default ProfileView;

const Container = styled.div`
  display: flex;
  width: 98%;
  flex-direction: column;
  margin: 1.5rem 1rem;
`;

const Banner = styled.div`
  gap: 1rem;
  background-color: #fff;
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  display: flex;
  padding: 1rem;
  background: #eaeaf3;
  border-radius: 10px;
`;

const VerifyBanner = styled.div`
  gap: 1rem;
  background-color: #fff;
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  display: flex;
  padding: 1rem;
  background: #eaeaf3;
  border-radius: 10px;
  justify-content: space-between;
`;

export const ImageWrapper = styled.div`
  background-color: #fff;
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 8rem;
  width: 8rem;
  border-radius: 5px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  gap: 1rem;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const FlexContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

const TableWrapper = styled.div`
  padding-bottom: 2rem;
  overflow: auto;
  padding-right: 1rem;
  max-height: 50vh;
  min-height: 30vh;
`;

const TableOuterWrapper = styled.div`
  padding: 1rem;
  border-radius: 10px;
  margin-top: 2.5rem;
  background: #eaeaf3;
`;
