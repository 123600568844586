import { useMediaQuery } from "@mui/material";
import styled from "styled-components";
import NotificationBar from "../../shared/navigation/NotificationBar";
import { useLocation } from "react-router";

import SideNavigation from "../../shared/navigation/SideNavigation";
import MobileNavigation from "../../shared/navigation/MobileNavigation";
import { useStateValue } from "../../../context/StateProvider";
import { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import UserLayout from "./UserLayout";
import CorporateLayout from "./CorporateLayout";

const DashboardLayout = () => {
  const [{ users }, dispatch] = useStateValue();
  const currentUser = useSelector((state) => state.currentUser);
  const matches = useMediaQuery("(max-width:768px)");
  const location = useLocation();

  useEffect(() => {
    try {
      dispatch({
        type: "CLEAR_MERCHANTS",
      });
      dispatch({
        type: "CLEAR_USERS",
      });
    } catch (error) {
      console.log(error);
    }
  }, [location, dispatch]);

  const ViewSwitch = () => {
    if (currentUser) {
      if (currentUser.type === "user") return <UserLayout />;
      else if (currentUser.type === "merchant") return <CorporateLayout />;
    }
  };

  return (
    <Container>
      <SideNavigation isMerchant={currentUser?.type === "merchant"} />
      <ScrollContainer>
        {matches && <MobileNavigation addBackground />}
        <NotificationBar />
        <Fragment>{ViewSwitch()}</Fragment>
      </ScrollContainer>
    </Container>
  );
};

export default DashboardLayout;

const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
`;

const ScrollContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const OverLayContainer = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  width: 100%;
  height: 100vh;
  left: 0;
  background-color: #0f0f3092;
`;
