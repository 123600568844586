const axios = require("axios");

const AUTH_URL = process.env.REACT_APP_AUTH_URL;
const USERS_URL = process.env.REACT_APP_USERS_URL;
const MERCHANTS_URL = process.env.REACT_APP_MERCHANTS_URL;
const CONSENTS_URL = process.env.REACT_APP_CONSENTS_URL;

// AUTH API //
export async function register(data) {
  try {
    const response = await axios.post(AUTH_URL + "register", data.body);
    return response;
  } catch (error) {
    console.error(error);
  }
}

// USERS API //
export async function retrieveUser(data) {
  try {
    const response = await axios.get(USERS_URL + data.id);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function retrieveUsersVerifications(data) {
  try {
    const response = await axios.get(
      USERS_URL + data.userPk + "/verifications"
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function searchUser(data) {
  try {
    const response = await axios.get(
      USERS_URL + "search?key=" + data.query.key
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function addKYC(data) {
  try {
    const response = await axios.put(
      USERS_URL + data.publicKey + "/kyc",
      data.body,
      {
        headers: {
          "x-auth-token": data.token,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function updateUser(data) {
  try {
    const response = await axios.put(USERS_URL + data.publicKey, data.body, {
      headers: {
        "x-auth-token": data.token,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function deleteUser(data) {
  try {
    const response = await axios.delete(USERS_URL + data.publicKey, {
      headers: {
        "x-auth-token": data.token,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

// MERCHANTS API //

export async function retrieveMerchant(data) {
  try {
    const response = await axios.get(MERCHANTS_URL + "?mId=" + data.id);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function retrieveMerchants() {
  try {
    const response = await axios.get(MERCHANTS_URL);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function retrieveMerchantsVerifications(data) {
  try {
    const response = await axios.get(
      MERCHANTS_URL + data.merchantPk + "/verifications"
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function searchMerchant(data) {
  try {
    const response = await axios.get(MERCHANTS_URL + "search?key=" + data);
    return response;
  } catch (error) {
    // console.error(error);
  }
}

export async function unverifyUser(data) {
  try {
    const response = await axios.delete(
      MERCHANTS_URL + "unverify-user",
      data.body,
      {
        headers: {
          "x-auth-token": data.token,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function verifyUser(data) {
  try {
    const response = await axios.post(
      MERCHANTS_URL + "verify-user",
      data.body,
      {
        headers: {
          "x-auth-token": data.token,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function updateMerchant(data) {
  try {
    const response = await axios.put(
      MERCHANTS_URL + data.publicKey,
      data.body,
      {
        headers: {
          "x-auth-token": data.token,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function deleteMerchant(data) {
  try {
    const response = await axios.delete(MERCHANTS_URL + data.publicKey, {
      headers: {
        "x-auth-token": data.token,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

// CONSENTS API //
export async function createConsent(data) {
  try {
    const response = await axios.post(CONSENTS_URL, data.body, {
      headers: {
        "x-auth-token": data.token,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function retrieveConsents(data) {
  try {
    const response = await axios.get(
      CONSENTS_URL +
        "?status=" +
        data.query.status +
        "&type=" +
        data.query.type +
        "&direction=" +
        data.query.direction,
      {
        headers: {
          "x-auth-token": data.token,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function reviewConsent(data) {
  try {
    const response = await axios.put(
      CONSENTS_URL + data.path.cId + "/review?status=" + data.query.status,
      data.body,
      {
        headers: {
          "x-auth-token": data.token,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function referConsent(data) {
  try {
    const response = await axios.post(CONSENTS_URL + "refer", data.body, {
      headers: {
        "x-auth-token": data.token,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function retrieveReferConsents(data) {
  try {
    const response = await axios.get(
      CONSENTS_URL + "refer?type=" + data.query.type,
      {
        headers: {
          "x-auth-token": data.token,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function reviewReferConsents(data) {
  try {
    const response = await axios.put(
      CONSENTS_URL +
        data.path.cId +
        "/referrals/review?status=" +
        data.query.status,
      data.body,
      {
        headers: {
          "x-auth-token": data.token,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function reviewReferrals(data) {
  try {
    const response = await axios.put(
      CONSENTS_URL +
        data.path.cId +
        "/referrals/review?status=" +
        data.query.status,
      data.body,
      {
        headers: {
          "x-auth-token": data.token,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export const decodeUser = async (data) => {
  try {
    const response = await axios.get(AUTH_URL + "me", {
      headers: {
        "x-auth-token": data.token,
      },
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};
