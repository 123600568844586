import React from "react";
import styled from "styled-components";
import TableHeadItem from "./TableHeadItem";
import TableRow from "./TableRow";

export default function Table(props) {
  return (
    <TableContainer className={props.customClass}>
      <TableHead>
        <Tr>
          {props.theadData.map((h, key) => {
            return <TableHeadItem key={key} item={h} />;
          })}
        </Tr>
      </TableHead>
      <tbody>
        {props.tbodyData?.map((item, key) => {
          if (props.customRow) {
            return (
              <TableRow
                key={key}
                data={item}
                tableView={props.tableView}
                customRow={props.customRow}
              />
            );
          } else if (props.transactions) {
            return (
              <TableRow
                key={key}
                data={item}
                tableView={props.tableView}
                transactions={props.transactions}
              />
            );
          } else {
            return (
              <TableRow key={key} data={item} tableView={props.tableView} />
            );
          }
        })}
      </tbody>
    </TableContainer>
  );
}

const TableContainer = styled.table`
  width: 100%;
  color: #2f3a60;
  height: 100%;
  border-collapse: collapse;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;

const TableHead = styled.thead`
  width: 100%;
  position: sticky;
  z-index: 99;
  top: 0;
  background: #eaeaf3;
  left: 0;
  text-align: center;
`;

const Tr = styled.tr`
  &:last-child {
    border-right: 0;
  }
`;
