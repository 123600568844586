import React, { Fragment } from "react";
import styled from "styled-components";
import { Text } from "../../Typography/Typo";
import CancelIcon from "@mui/icons-material/Cancel";
import { useSnackbar } from "notistack";
import { useStateValue } from "../../../../context/StateProvider";

export const TextField = (props) => {
  //eslint-disable-next-line
  const [{ fields }, dispatch] = useStateValue();
  const { enqueueSnackbar } = useSnackbar();

  const handleRemoveField = (name) => {
    if (
      window.confirm(
        `Are you sure you want to delete the field named ${props.label} ? `
      )
    ) {
      enqueueSnackbar(`Field named ${props.label} Removed!`, {
        variant: "warning",
      });
      dispatch({
        type: `${props.stageType ? "REMOVE_STAGE_FIELD" : "REMOVE_FIELD"}`,
        id: name,
      });
    }
  };

  return (
    <Container span={Boolean(props.span)} style={props.style}>
      <InputContainer custom={props.custom}>
        <Label style={{ paddingBottom: "5px" }} id={props.id}>
          {props.label}
        </Label>
        {props.custom && (
          <CancelIcon
            onClick={() => {
              handleRemoveField(props.id);
            }}
            size="small"
            style={{ color: "#6e6e6eca", cursor: "pointer" }}
          />
        )}
      </InputContainer>
      <TextInputContainer>
        <Fragment>{props.startIcon}</Fragment>
        <Input
          form={props.form}
          disabled={props.disabled}
          type={props.type}
          placeholder={props.placeholder}
          required={props.required}
          onChange={
            props.onChange && (({ target: { value } }) => props.onChange(value))
          }
          value={props.value}
          name={props.name}
          id={props.id}
          error={props.error}
          control={props.control}
          onBlur={props.onBlur}
          onKeyPress={props.onKeyPress}
          maxLength={props.maxLength}
        />
        <Fragment>{props.icon}</Fragment>
      </TextInputContainer>
      <FlexContainer>
        <Text lighter small color={props.color}>
          {props.helper}
        </Text>
      </FlexContainer>
    </Container>
  );
};

const TextInputContainer = styled.div`
  display: flex;
  padding: 0.5rem;
  background-color: #eaeaf3;
  border-radius: 5px;
  align-items: center;
  border: 0.75px solid rgba(57, 70, 115, 0.103);
`;

const Input = styled.input`
  border: none;
  width: 100%;
  padding: 0 0.5rem;
  outline: none;
  color: #2f3a60;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  background-color: transparent;
  @media (max-width: 1368px) {
    font-size: 14px;
    width: 50%;
  }
`;

export const Label = styled.label`
  color: #2f3a60;
  font-weight: 700;
`;

export const Container = styled.div`
  gap: 0.3rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  grid-column: ${(props) => (props.span ? "1/3" : "unset")};
  @media (max-width: 768px) {
    grid-column: unset;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  align-items: center;
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
