import { useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { CustomButton } from "../../components/shared/fields/Button/CustomButton";
import DropzoneComponent from "../../components/shared/fields/Dropzone/DropzoneComponent";
import CustomTextField from "../../components/shared/fields/TextField/CustomTextField";
import { useSnackbar } from "notistack";
import { addKYC } from "../../api/Api";
import {
  ADD_FORM_DATA,
  ADD_USER_DETAILS,
} from "../../redux/constants/ActionTypes";
import store from "../../redux/reducers";
import * as encryption from "../../utils/encryption";
import ActionConfirm from "../ActionConfirm";
import { getUserSession } from "../../services/UserManagement";
import { CircularProgress } from "@mui/material";

var phoneNoValidation =
  /^(?:0|94|\+94)?(?:(11|21|23|24|25|26|27|31|32|33|34|35|36|37|38|41|45|47|51|52|54|55|57|63|65|66|67|81|912)(0|2|3|4|5|7|9)|7(0|1|2|4|5|6|7|8)\d)\d{6}$/;

const VerifyDetails = ({ setFormStep }) => {
  const currentUser = useSelector((state) => state.currentUser);
  const kycData = useSelector((state) => state.kycData);
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(
    store.getState().kycData.phoneNumber
      ? store.getState().kycData.phoneNumber
      : ""
  );
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [passportImageBase64, setPassportImageBase64] = useState([]);
  const [addressImageBase64, setAddressImageBase64] = useState([]);
  const [showAction, setShowAction] = useState(false);
  const [authUser, setAuthUser] = useState(() => getUserSession());

  // getting encrypt and product data
  const getData = () => {
    var productObject = {};
    var encryptedObject = {};
    var passportImage = [];
    var addressImage = [];
    for (const [key, value] of Object.entries(kycData)) {
      productObject[key] = value;
      if (
        key !== "profileImage" &&
        key !== "birthCertificate" &&
        key !== "drivingLicense" &&
        key !== "marriageCertificate" &&
        key !== "signature" &&
        key !== "managerSignature" &&
        key !== "passportImage" &&
        key !== "addressImage"
      ) {
        encryptedObject[key] = encryption.createEncryptionObject(
          "SYMMETRIC",
          value,
          currentUser.publicKey,
          currentUser.publicKey,
          currentUser.secretKey
        );
      } else {
        const tempArr = [];
        kycData[key]?.map((item, key) => {
          const enc = encryption.createEncryptionObject(
            "SYMMETRIC",
            item,
            currentUser.publicKey,
            currentUser.publicKey,
            currentUser.secretKey
          );
          tempArr.push(enc);
        });
        encryptedObject[key] = tempArr;
      }
    }
    productObject["passportImage"] = passportImageBase64;
    productObject["addressImage"] = addressImageBase64;
    productObject["profileImage"] = kycData.profileImageBase64;
    productObject["birthCertificate"] = kycData.birthCertificateBase64;
    productObject["drivingLicense"] = kycData.drivingLicenseBase64;
    productObject["marriageCertificate"] = kycData.marriageCertificateBase64;
    productObject["signature"] = kycData.signatureBase64;
    productObject["managerSignature"] = kycData.managerSignatureBase64;
    // --- Passport image --- //
    passportImageBase64.map((item, key) => {
      const enc = encryption.createEncryptionObject(
        "SYMMETRIC",
        item,
        currentUser.publicKey,
        currentUser.publicKey,
        currentUser.secretKey
      );
      passportImage.push(enc);
    });
    encryptedObject.passportImage = passportImage;

    // --- Address image --- //
    addressImageBase64.map((item, key) => {
      const enc = encryption.createEncryptionObject(
        "SYMMETRIC",
        item,
        currentUser.publicKey,
        currentUser.publicKey,
        currentUser.secretKey
      );
      addressImage.push(enc);
    });
    encryptedObject.addressImage = addressImage;

    // --- For Mobile number -- //
    encryptedObject.phoneNumber = encryption.createEncryptionObject(
      "SYMMETRIC",
      phoneNumber,
      currentUser.publicKey,
      currentUser.publicKey,
      currentUser.secretKey
    );
    productObject["phoneNumber"] = phoneNumber;

    return { encryptedObject, productObject };
  };

  // addKYC api
  const addKycApi = async () => {
    const { encryptedObject, productObject } = getData();
    const data = {
      body: encryptedObject,
      token: localStorage.getItem("niftoken"),
      publicKey: currentUser.publicKey,
    };
    try {
      setLoading(true);
      const response = await addKYC(data);
      if (response.data.code === 202) {
        store.dispatch({
          type: ADD_FORM_DATA,
          payload: productObject,
        });
        store.dispatch({
          type: ADD_USER_DETAILS,
          payload: { ...currentUser, userOwnedData: encryptedObject },
        });
        enqueueSnackbar("KYC added Successfully", {
          variant: "success",
        });
        setFormStep(3);
      }
    } catch (error) {
      enqueueSnackbar("ADD KYC API FAILED", {
        variant: "fail",
      });
    } finally {
      setLoading(false);
      setShowAction(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (passportImageBase64?.length === 0) {
      enqueueSnackbar(
        `Please input images of your NIC / Passport / Driving License`,
        {
          variant: "error",
        }
      );
      return false;
    } else if (addressImageBase64?.length === 0) {
      enqueueSnackbar(`Please input images of your address proof`, {
        variant: "error",
      });
      return false;
    } else if (!phoneNumberError) {
      setShowAction(true);
    }
  };

  return (
    <Container>
      {showAction && (
        <ActionConfirm
          successMessage={{
            message: "Adding KYC details.",
            width: "9.7rem",
          }}
          warningMessage={"Are you sure you want to submit?"}
          loading={loading}
          setShowAction={setShowAction}
          authTypeInput={authUser.authType}
          publicKey={authUser.publicKey}
          encryptedSecret={authUser.encryptedSecret}
          onSecretKey={addKycApi}
        />
      )}
      <GridContainer onSubmit={handleSubmit}>
        <LeftContainer>
          <Label>Upload NIC / Passport / Driving License</Label>
          <DroppableContainer>
            <InnerContainer>
              <DropzoneComponent
                files={passportImageBase64}
                required
                id="dropzone"
                amount="2"
                multiple={true}
                maxSize="2000000"
                setFiles={setPassportImageBase64}
                type="image/*, image/jpeg, image/png"
                description="Select or Drop Images"
              />
            </InnerContainer>
          </DroppableContainer>
        </LeftContainer>
        <RightContainer>
          <Label>Upload Address Proof</Label>
          <DroppableContainer>
            <InnerContainer>
              <DropzoneComponent
                files={addressImageBase64}
                id="dropzone"
                amount="2"
                maxSize="2000000"
                multiple={true}
                setFiles={setAddressImageBase64}
                type="image/*, image/jpeg, image/png"
                description="Select or Drop Images"
              />
            </InnerContainer>
          </DroppableContainer>
        </RightContainer>
        <SplitContainer>
          <CustomTextField
            label="Mobile Number*"
            placeholder="Mobile Number"
            id="phoneNumber"
            name="phoneNumber"
            type="text"
            value={phoneNumber}
            required
            maxLength="13"
            onChange={(e) => {
              if (!phoneNoValidation.test(e)) {
                setPhoneNumberError(true);
              } else {
                setPhoneNumberError(false);
              }
              setPhoneNumber(e);
            }}
            color="#FF613F"
            helper={phoneNumberError && "Invalid mobile number"}
          />
          {/* <OtpButtonContainer>
          <OtpButton type="submit">Send OTP</OtpButton>
        </OtpButtonContainer> */}
        </SplitContainer>
        <ButtonWrapper>
          {!loading ? (
            <CustomButton type="submit" login text="Submit" />
          ) : (
            <CircularProgress size={30} />
          )}
        </ButtonWrapper>
      </GridContainer>
    </Container>
  );
};

export default VerifyDetails;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.noGap ? "0" : "1rem")};
  background-color: #eff2f5;
  border: 0.75px solid #d3d3d36e;
  border-radius: 10px;
  padding: 1.5rem;
`;

const GridContainer = styled.form`
  display: grid;
  margin: 2rem;
  row-gap: 1rem;
  column-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 768px) {
    display: flex;
    margin: 1rem;
    flex-direction: column;
  }
`;

const ButtonWrapper = styled.div`
  padding-top: 1rem;
  display: flex;
  grid-column: 1/3;
  justify-content: flex-end;
`;

const LeftContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
const RightContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Label = styled.label`
  color: #2f3a60;
  font-weight: 700;
  font-size: 16px;
  margin-top: 1rem;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const DroppableContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  row-gap: 1rem;
  align-items: center;
  justify-content: space-evenly;
`;

const InnerContainer = styled.div`
  height: 10rem;
  text-align: center;
  word-wrap: break-word;
  border: dashed 0.75px #ae9bf0a3;
  width: 100%;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #c3d0e147;
`;

const OtpButtonContainer = styled.div`
  position: absolute;
  top: 45%;
  right: 1%;
  z-index: 99;
`;

const OtpButton = styled.button`
  border-radius: 5px;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  padding: 0.2rem 1rem;
  background-color: #2f3a60;
  color: #ffffff;
  text-transform: inherit;
  width: 100%;
  cursor: pointer;
`;

const SplitContainer = styled.div`
  grid-column: 1/3;
  position: relative;
`;
