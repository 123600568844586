import { useParams } from "react-router-dom";
import CorporateUsers from "../../../corporate-pages/Corporate-Users/CorporateUsers";
import CorporateProfile from "../../../corporate-pages/Corporate-Profile/CorporateProfile";
import CorporateDashboard from "../../../corporate-pages/Corporate-Dashboard/CorporateDashboard";
import Settings from "../../../pages/Settings/Settings";

const CorporateLayout = () => {
  const { tab } = useParams();
  return (
    <div>
      {(() => {
        switch (tab) {
          case "settings":
            return <Settings />;
          case "profile":
            return <CorporateProfile />;
          case "users":
            return <CorporateUsers />;
          default:
            return <CorporateDashboard />;
        }
      })()}
    </div>
  );
};

export default CorporateLayout;
