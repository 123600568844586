import styled from "styled-components";
import Image from "../../assets/images/user.jpg";
import { CustomButton } from "../../components/shared/fields/Button/CustomButton";
import { Text } from "../../components/shared/Typography/Typo";

const MyActivity = ({ from, to, timestamp }) => {
  return (
    <Container>
      <FlexContainer>
        <SmallAvatar image={Image} />
        <Text color="#ffffff">
          <strong>{from}</strong>
        </Text>
        <Text lighter color="#ffffff">
          &nbsp; added
        </Text>
        <Text color="#ffffff">
          <strong> &nbsp;{to}</strong>
        </Text>
        <Text lighter color="rgba(234, 234, 243, 0.46)">
          &nbsp; {timestamp}
        </Text>
      </FlexContainer>
      <Line />
      <FlexContainer style={{ justifyContent: "space-between" }}>
        <FlexContainer>
          <BigAvatar image={Image} />
          <ContentContainer>
            <Text color="rgb(255, 255, 255)">
              <strong>{to}</strong>
            </Text>
            <Text small lighter color="rgba(234, 234, 243, 0.46)">
              Verified by 14 Corporates
            </Text>
          </ContentContainer>
        </FlexContainer>
        <CustomButton text="Add User" login />
      </FlexContainer>
    </Container>
  );
};

export default MyActivity;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

const SmallAvatar = styled.div`
  width: 2rem;
  height: 2rem;
  background-color: #fff;
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 50px;
  border: 2px solid #ffffff;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Line = styled.span`
  background-color: rgba(255, 255, 255, 0.2);
  height: 0.7px;
`;

const BigAvatar = styled.div`
  width: 5rem;
  height: 5rem;
  background-color: #fff;
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 50px;
  border: 2px solid #ffffff;
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-right: 2rem;
  @media (max-width: 768px) {
    width: 4rem;
    height: 4rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
