import styled from "styled-components";
import { Container, LogoContainer } from "./MainNavigation";
import Logo from "../../../assets/images/logo 1.png";
import MenuIcon from "@mui/icons-material/Menu";

import { Link } from "react-router-dom";
import { Text } from "../Typography/Typo";

import DashboardIcon from "@mui/icons-material/Dashboard";
import HomeIcon from "@mui/icons-material/Home";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import SettingsIcon from "@mui/icons-material/Settings";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { OverLayContainer } from "../../layouts/MainLayout/DashboardLayout";

const MobileNavigation = ({ addBackground }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    }
    return () => (document.body.style.overflow = "unset");
  }, [open]);

  return (
    <Container addBackground={addBackground}>
      {open && <OverLayContainer onClick={() => setOpen(false)} />}
      <LogoContainer src={Logo} />
      <IconContainer>
        <NavContainer
          style={{
            transform: `${open ? "translateY(0)" : "translate(-100%)"}`,
          }}
        >
          <NavUpper>
            <NavLogo>
              <LogoContainer src={Logo} style={{ marginLeft: "-0.2rem" }} />
              <Text color="#fff" small lighter>
                KYC Software
              </Text>
            </NavLogo>
            <NavLink to="/">
              Home <HomeIcon />
            </NavLink>
            <NavLink to="/dashboard">
              Dashboard
              <DashboardIcon />
            </NavLink>
            <NavLink to="/dashboard/profile">
              Profile <AssignmentIndIcon />
            </NavLink>
            <NavLink to="/dashboard/users">
              Users
              <PeopleAltIcon />
            </NavLink>
            <NavLink to="/dashboard/corporates">
              Corporates
              <CorporateFareIcon />
            </NavLink>
          </NavUpper>
          <NavLink to="/dashboard/settings">
            Settings
            <SettingsIcon />
          </NavLink>
        </NavContainer>
        <IconButton onClick={() => setOpen((value) => !value)}>
          {open ? (
            <CloseRoundedIcon
              style={{ color: "#fff", cursor: "pointer", zIndex: "999" }}
            />
          ) : (
            <MenuIcon
              style={{ color: "#fff", cursor: "pointer", zIndex: "999" }}
            />
          )}
        </IconButton>
      </IconContainer>
    </Container>
  );
};

export default MobileNavigation;

const NavContainer = styled.div`
  z-index: 999;
  padding: 2rem 1rem;
  height: 93.1vh;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 0;
  left: 0;
  gap: 3rem;
  background: linear-gradient(180deg, #2f3a60 0%, #1d2439 100%);
  width: 16rem;
  transform: translate(-100%);
  box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  transition: transform 500ms cubic-bezier(0.165, 0.84, 0.44, 1);
  @media (max-width: 1024px) {
    width: 15rem;
  }
  @media (max-height: 450px) {
    height: auto;
  }
`;

export const IconContainer = styled.div``;

const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  padding: 0.65rem 2rem;
  background: #394673;
  border-radius: 5px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  line-height: 25px;
`;

export const NavUpper = styled.div`
  display: flex;
  position: relative;
  gap: 1rem;
  flex-direction: column;
`;

export const NavLogo = styled.div`
  border-bottom: 0.75px solid #9292924b;
  margin-bottom: 1rem;
  padding-bottom: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
