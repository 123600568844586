import styled from "styled-components";
import SearchIcon from "@mui/icons-material/Search";
import CustomTextField from "../TextField/CustomTextField";
import SearchView from "./SearchView";
// import { DateComponent } from "./Date/DateComponent";

const Search = (props) => {
  return (
    <FlexContainer half={props.half}>
      <CustomTextField
        placeholder={props.placeholder}
        startIcon={<SearchIcon />}
        value={props.searchValue}
        onChange={props.onSearchChange}
        onKeyUp={props.onKeyUp}
      />
      {/* <DateComponent value={props.dateValue} onChange={props.onDateChange} /> */}
      {props.withSelect && props.searchValue !== "" && (
        <SearchView
          loading={props.loading}
          error={props.error}
          data={props.data}
          search={props.searchValue}
          setSearch={props.onSearchChange}
          reducer={props.reducer}
        />
      )}
    </FlexContainer>
  );
};

export default Search;

const FlexContainer = styled.div`
  position: relative;
  display: flex;
  width: ${(props) => (props.half ? "50%" : "auto")};
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
`;
