import React from "react";
import DatePicker from "react-date-picker";
import styled from "styled-components";
import "./DatePicker.css";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

export const DateComponent = (props) => {
  return (
    <Container span={props.span}>
      <InputContainer>
        <Label id={props.id}>{props.label}</Label>
      </InputContainer>
      <TextInputContainer>
        <DatePicker
          monthPlaceholder="MM"
          yearPlaceholder="YY"
          dayPlaceholder="DD"
          onChange={props.onChange}
          value={props.value}
          clearIcon={false}
          calendarIcon={<CalendarTodayIcon style={{ color: "#2F3A60" }} />}
          className="custom-date"
        />
      </TextInputContainer>
    </Container>
  );
};

const TextInputContainer = styled.div`
  display: flex;
  background-color: #eaeaf3;
  padding: 0.2rem;
  border-radius: 5px;
  align-items: center;
  border: 0.75px solid rgba(57, 70, 115, 0.103);
`;

export const Label = styled.label`
  color: #2f3a60;
  font-weight: 700;
`;

export const Container = styled.div`
  gap: 0.3rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  grid-column: ${(props) => (props.span ? "1/3" : "unset")};
  @media (max-width: 768px) {
    grid-column: unset;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
