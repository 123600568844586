import styled from "styled-components";
import Logo from "../../../assets/images/logo 1.png";
import LogoSmall from "../../../assets/images/logo-small.svg";
import { Text } from "../Typography/Typo";
import DashboardIcon from "@mui/icons-material/Dashboard";
import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from "@mui/icons-material/Settings";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import { NavLogo, NavUpper } from "./MobileNavigation";
import { LogoContainer } from "./MainNavigation";
import { Tooltip, useMediaQuery } from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { useState } from "react";
import { useStateValue } from "../../../context/StateProvider";

const SideNavigation = ({ isMerchant }) => {
  const [{ currentUser }, dispatch] = useStateValue();
  const matches = useMediaQuery("(max-width:1368px)");
  const [hide, setHide] = useState(false);
  return (
    <Container
      style={{
        width: `${
          (hide && "3.5rem") ||
          (!hide && !matches && "20rem") ||
          (matches && "3.5rem")
        }`,
        padding: `${hide ? "0rem 1rem" : "0rem 1.5rem"}`,
      }}
    >
      {matches ? (
        <>
          <NavUpper style={{ marginTop: "1rem" }}>
            <Link to="/">
              <NavLogo>
                <SmallLogoContainer
                  src={LogoSmall}
                  style={{ marginLeft: "0.6rem" }}
                />
              </NavLogo>
            </Link>
            <Tooltip title="Home">
              <LinkButton to="/" activeClassName="selected">
                <HomeIcon />
              </LinkButton>
            </Tooltip>
            <Tooltip title="Dashboard">
              <LinkButton to="/dashboard">
                <DashboardIcon />
              </LinkButton>
            </Tooltip>
            <Tooltip title="Profile">
              <LinkButton to="/dashboard/profile">
                <AssignmentIndIcon />
              </LinkButton>
            </Tooltip>
            <Tooltip title="Users">
              <LinkButton to="/dashboard/users">
                <PeopleAltIcon />
              </LinkButton>
            </Tooltip>
            {!isMerchant && (
              <Tooltip title="Corporates">
                <LinkButton to="/dashboard/corporates">
                  <CorporateFareIcon />
                </LinkButton>
              </Tooltip>
            )}
          </NavUpper>
        </>
      ) : (
        <>
          {hide ? (
            <NavUpper style={{ marginTop: "1rem" }}>
              <MobileNextButton onClick={() => setHide((value) => !value)}>
                <ArrowForwardIcon style={{ color: "#394673" }} />
              </MobileNextButton>
              <Link to="/">
                <NavLogo>
                  <SmallLogoContainer
                    src={LogoSmall}
                    style={{ marginLeft: "0.6rem" }}
                  />
                </NavLogo>
              </Link>

              <Tooltip title="Home">
                <LinkButton
                  style={{ padding: `${hide && "1rem"}` }}
                  to="/"
                  activeClassName="selected"
                >
                  <HomeIcon />
                </LinkButton>
              </Tooltip>

              <Tooltip title="dashboard">
                <LinkButton
                  style={{ padding: `${hide && "1rem"}` }}
                  to="/dashboard"
                >
                  <DashboardIcon />
                </LinkButton>
              </Tooltip>

              <Tooltip title="Profile">
                <LinkButton
                  style={{ padding: `${hide && "1rem"}` }}
                  to="/dashboard/profile"
                >
                  <AssignmentIndIcon />
                </LinkButton>
              </Tooltip>

              <Tooltip title="Users">
                <LinkButton
                  style={{ padding: `${hide && "1rem"}` }}
                  to="/dashboard/users"
                >
                  <PeopleAltIcon />
                </LinkButton>
              </Tooltip>
              {!isMerchant && (
                <Tooltip title="Corporates">
                  <LinkButton
                    style={{ padding: `${hide && "1rem"}` }}
                    to="/dashboard/corporates"
                  >
                    <CorporateFareIcon />
                  </LinkButton>
                </Tooltip>
              )}
            </NavUpper>
          ) : (
            <NavUpper style={{ marginTop: "1rem" }}>
              <NextButton onClick={() => setHide((value) => !value)}>
                <ArrowBackIcon style={{ color: "#394673" }} />
              </NextButton>
              <NavLogo>
                <Link to="/">
                  <LogoContainer src={Logo} style={{ marginLeft: "-0.5rem" }} />
                </Link>

                <Text color="#fff" small lighter>
                  {currentUser?.length > 0 &&
                  currentUser.data.type === "merchant"
                    ? "Corporate KYC"
                    : " KYC Software"}
                </Text>
              </NavLogo>
              <LinkButton to="/" activeClassName="selected">
                Home <HomeIcon />
              </LinkButton>
              <LinkButton to="/dashboard">
                Dashboard
                <DashboardIcon />
              </LinkButton>
              <LinkButton to="/dashboard/profile">
                Profile <AssignmentIndIcon />
              </LinkButton>
              <LinkButton to="/dashboard/users">
                Users
                <PeopleAltIcon />
              </LinkButton>
              {!isMerchant && (
                <LinkButton to="/dashboard/corporates">
                  Corporates
                  <CorporateFareIcon />
                </LinkButton>
              )}
            </NavUpper>
          )}
        </>
      )}
      {matches ? (
        <Tooltip title="Settings">
          <LinkButton style={{ marginBottom: "2rem" }} to="/dashboard/settings">
            <SettingsIcon />
          </LinkButton>
        </Tooltip>
      ) : (
        <Tooltip title="Settings">
          <LinkButton
            style={{
              marginBottom: "2rem",
              padding: `${hide ? "1rem" : "0.65rem 2rem"}`,
            }}
            to="/dashboard/settings"
          >
            {hide ? "" : "Settings"}
            <SettingsIcon />
          </LinkButton>
        </Tooltip>
      )}
    </Container>
  );
};

export default SideNavigation;

const Container = styled.div`
  position: sticky;
  top: 0;
  z-index: 999;
  left: 0;
  height: 100vh;
  padding: 0rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
  background: linear-gradient(180deg, #2f3a60 0%, #1d2439 100%);
  width: 20rem;
  transition: transform 500ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: width 500ms cubic-bezier(0.165, 0.84, 0.44, 1);
  @media (max-width: 768px) {
    display: none;
  }
  @media (max-height: 450px) {
    height: auto;
  }
`;

const LinkButton = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  padding: 0.65rem 2rem;
  background: #394673;
  border-radius: 5px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  line-height: 25px;
  &.active {
    background: #4f61a0;
  }
  @media (max-width: 1368px) {
    line-height: unset;
    padding: 1rem;
  }
`;

const SmallLogoContainer = styled.img`
  width: 2rem;
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
  height: auto;
`;

const NextButton = styled.div`
  position: absolute;
  top: 8%;
  right: -14%;
  cursor: pointer;
  display: grid;
  place-items: center;
  height: 30px;
  width: 30px;
  border-radius: 50px;
  box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.25);
  background-color: #fff;
`;

const MobileNextButton = styled.div`
  position: absolute;
  top: 8%;
  right: -55%;
  cursor: pointer;
  display: grid;
  place-items: center;
  height: 30px;
  width: 30px;
  border-radius: 50px;
  box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.25);
  background-color: #fff;
`;
