import styled from "styled-components";
import { Text } from "../../components/shared/Typography/Typo";

const Banner = ({
  textOne,
  headingOne,
  imageOne,
  headingTwo,
  textTwo,
  imageTwo,
  headingThree,
  textThree,
  imageThree,
}) => {
  return (
    <FlexContainer>
      <ImageWrapper>
        <ImageContainer>
          <Image src={imageOne} alt="logo" />
        </ImageContainer>
        <ImageContent>
          <Text color="#2F3A60" style={{ fontWeight: "600" }}>
            {headingOne}
          </Text>
          <Text color="#686868" lighter small>
            {textOne}
          </Text>
        </ImageContent>
      </ImageWrapper>
      <ImageWrapper>
        <ImageContainer>
          <Image src={imageTwo} alt="logo" />
        </ImageContainer>
        <ImageContent>
          <Text color="#2F3A60" style={{ fontWeight: "600" }}>
            {headingTwo}
          </Text>
          <Text color="#686868" lighter small>
            {textTwo}
          </Text>
        </ImageContent>
      </ImageWrapper>
      <ImageWrapper>
        <ImageContainer>
          <Image src={imageThree} alt="logo" />
        </ImageContainer>
        <ImageContent>
          <Text color="#2F3A60" style={{ fontWeight: "600" }}>
            {headingThree}
          </Text>
          <Text color="#686868" lighter small>
            {textThree}
          </Text>
        </ImageContent>
      </ImageWrapper>
    </FlexContainer>
  );
};

export default Banner;

const FlexContainer = styled.div`
  width: 100%;
  margin: 8rem 0rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-evenly;
  @media (max-width: 1568px) {
    flex-direction: column;
    margin: 3rem 0rem;
  }
`;

const ImageContainer = styled.div`
  width: 10rem;
  padding: 1rem;
  display: grid;
  place-items: center;
  height: 10rem;
  border-radius: 50%;
  background-color: #fff;
`;

const ImageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 0.2rem;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;
