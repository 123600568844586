import styled from "styled-components";
import MainNavigation from "../../components/shared/navigation/MainNavigation";
import useMediaQuery from "@mui/material/useMediaQuery";
import MobileNavigation from "../../components/shared/navigation/MobileNavigation";
import { Heading, Text } from "../../components/shared/Typography/Typo";
import maskOne from "../../assets/images/mask-1.png";
import { Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useHistory } from "react-router";
import MyActivity from "./MyActivity";

const Home = () => {
  const matches = useMediaQuery("(max-width:768px)");
  const history = useHistory();
  return (
    <Container>
      {matches ? (
        <MobileNavigation />
      ) : (
        <MainNavigation isLoggedIn={localStorage.getItem("niftoken")} />
      )}
      <GridContainer>
        <ContentContainer>
          <Heading main color="#fff">
            Know Your Customer
          </Heading>
          <Text lighter color="#fff">
            Blockchain-based Know Your Customer application that will verify and
            provide trust instantly for an unknown party when they onboard.
          </Text>
          <Button
            onClick={() => history.push("/dashboard")}
            endIcon={<ArrowForwardIcon />}
            style={{
              width: "11rem",
              backgroundColor: "#23C99E",
              color: "#fff",
              fontFamily: "Open Sans",
              fontStyle: "normal",
              textTransform: "capitalize",
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "25px",
              textAlign: "center",
            }}
          >
            Go to Dashboard
          </Button>
        </ContentContainer>
        {localStorage.niftoken && (
          <ScrollWrapper>
            <TopFade />
            <ScrollContainer>
              <ColumnContainer>
                <MyActivity
                  from="John Doe"
                  to="Cassie Doe"
                  timestamp="an hour ago"
                />
                <MyActivity
                  from="John Doe"
                  to="Cassie Doe"
                  timestamp="an hour ago"
                />
                <MyActivity
                  from="John Doe"
                  to="Cassie Doe"
                  timestamp="an hour ago"
                />
                <MyActivity
                  from="John Doe"
                  to="Cassie Doe"
                  timestamp="an hour ago"
                />
                <MyActivity
                  from="John Doe"
                  to="Cassie Doe"
                  timestamp="an hour ago"
                />
                <MyActivity
                  from="John Doe"
                  to="Cassie Doe"
                  timestamp="an hour ago"
                />
              </ColumnContainer>
            </ScrollContainer>
            <BottomFade />
          </ScrollWrapper>
        )}
      </GridContainer>
      <MaskOne src={maskOne} />
    </Container>
  );
};

export default Home;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  padding: 0rem 1rem;
  background: linear-gradient(180deg, #2f3a60 0%, #1d2439 100%);
  @media (max-width: 1368px) {
    height: auto;
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  @media (max-width: 1368px) {
    grid-template-columns: 1fr;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  z-index: 2;
  flex-direction: column;
  padding: 4rem 2rem;
  gap: 1rem;
  @media (max-width: 1368px) {
    width: 70%;
  }
  @media (max-width: 768px) {
    padding: 2rem;
    width: auto;
  }
`;

const MaskOne = styled.img`
  position: absolute;
  top: 0;
  height: auto;
  width: 25rem;
  left: 0%;
`;

const ColumnContainer = styled.div`
  display: flex;
  padding: 4rem 2rem;
  flex-direction: column;
  @media (max-width: 768px) {
    padding: 2rem;
  }
`;

const ScrollContainer = styled.div`
  margin-top: 2rem;
  overflow: auto;
  scroll-behavior: smooth;
  max-height: 85vh;
  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
`;

const ScrollWrapper = styled.div`
  position: relative;
  margin: 1rem 0rem;
`;

const TopFade = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  height: 12rem;
  width: 100%;
  background: linear-gradient(180deg, transparent 0%, #2d385c 88.74%);
  transform: matrix(1, 0, 0, -1, 0, 0);
  @media (max-width: 1368px) {
    display: none;
  }
`;

const BottomFade = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 99;
  height: 8rem;
  width: 100%;
  background: linear-gradient(180deg, transparent 0%, #1d243a 88.74%);
`;
