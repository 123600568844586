export const USER_FIELDS = [
  {
    name: "firstName",
    label: "First Name",
  },
  {
    name: "lastName",
    label: "Last Name",
  },
  {
    name: "maidenName",
    label: "Maiden Name",
  },
  {
    name: "otherNames",
    label: "Other Name",
  },
  {
    name: "nameWithInitials",
    label: "Name with Initials",
  },
  {
    name: "placeOfBirth",
    label: "Place of Birth",
  },
  {
    name: "nationality",
    label: "Nationality",
  },
  {
    name: "addressL1",
    label: "Address Line 1",
  },
  {
    name: "addressL2",
    label: "Address Line 2",
  },
  {
    name: "city",
    label: "City",
  },
  {
    name: "postalCode",
    label: "Postal Code",
  },
  {
    name: "country",
    label: "Country",
  },
  {
    name: "foreignAddress",
    label: "Foreign Address",
  },
  {
    name: "phoneNumber",
    label: "Phone Number",
  },
  {
    name: "email",
    label: "Email",
  },
  {
    name: "nic",
    label: "NIC / Passport / Driving Lisence No.",
  },
  {
    name: "profileImage",
    label: "Profile Image",
  },
  {
    name: "birthCertificate",
    label: "Birth Certificate",
  },
  {
    name: "signature",
    label: "Signature",
  },
  {
    name: "passportImage",
    label: "Passport Image",
  },
  {
    name: "addressImage",
    label: "Address Image",
  },
];

export const MERCHANT_FIELDS = [
  {
    name: "companyName",
    label: "Company Name",
  },
  {
    name: "brcNo",
    label: "BRC No",
  },
  {
    name: "employeeCount",
    label: "Employee Count",
  },
  {
    name: "addressL1",
    label: "Address Line 1",
  },
  {
    name: "addressL2",
    label: "Address Line 2",
  },
  {
    name: "city",
    label: "City",
  },
  {
    name: "country",
    label: "Country",
  },
  {
    name: "employeeCount",
    label: "Employee Count",
  },
  {
    name: "phoneNumber",
    label: "Phone Number",
  },
  {
    name: "email",
    label: "Email",
  },
  {
    name: "logo",
    label: "Logo",
  },
  {
    name: "brcImage",
    label: "BRC Image",
  },
  {
    name: "addressImage",
    label: "Address Image",
  },
];
