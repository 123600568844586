import styled from "styled-components";
import SearchRow from "./SearchRow";
import Test from "../../../../assets/images/text logo.png";
import User from "../../../../assets/images/user.jpg";
import { CircularProgress } from "@mui/material";
import { Text } from "../../Typography/Typo";
import AnonymousImage from "../../../../assets/images/anonymous.png";

const SearchView = ({ data, search, setSearch, reducer, loading, error }) => {
  return (
    <Container setMinHeight>
      <ScrollContainer>
        {error || loading ? (
          <FlexContainer>
            {loading ? (
              <CircularProgress size={20} />
            ) : (
              <Text small color="#394673">
                {error && "Not Found"}
              </Text>
            )}
          </FlexContainer>
        ) : (
          // data
          // .filter((searchItem) => {
          //   if (search === "") {
          //     return searchItem;
          //   } else if (
          //     searchItem.name &&
          //     searchItem.name.toLowerCase().includes(search)
          //   ) {
          //     return searchItem;
          //   } else if (
          //     searchItem.location &&
          //     searchItem.location.toLowerCase().includes(search)
          //   ) {
          //     return searchItem;
          //   }
          // })
          data?.map((searchItem, key) => {
            return (
              <SearchRow
                key={key}
                reducer={reducer}
                image={
                  data && searchItem.type === "user"
                    ? User
                    : Test || AnonymousImage
                }
                data={searchItem}
                setSearch={setSearch}
              />
            );
          })
        )}
      </ScrollContainer>
    </Container>
  );
};

export default SearchView;

const Container = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  position: absolute;
  z-index: 99;
  top: 130%;
  background-color: #ffffff;
  left: 0;
`;

const ScrollContainer = styled.div`
  min-height: 3rem;
  max-height: 12rem;
  position: relative;
  display: flex;
  margin-right: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  flex-direction: column;
  overflow: auto;
  &:empty {
    display: none;
  }
`;

const FlexContainer = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  background: #eaeaf3;
  align-items: center;
  border-radius: 5px;
  margin: 0.5rem 1rem;
  padding: 0.5rem 1rem;
`;
