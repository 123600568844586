import styled from "styled-components";
import Search from "./Search";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const SearchWithTabs = ({
  setSearch,
  searchValue,
  setTabState,
  loading,
  error,
  tabState,
  placeholder,
  categoryOne,
  categoryTwo,
  categoryThree,
  categoryFour,
  data,
  reducer,
  onKeyUp,
}) => {
  const currentUser = useSelector((state) => state.currentUser);
  const location = useLocation();
  return (
    <Container>
      <WrapperContainer>
        <Search
          placeholder={placeholder}
          searchValue={searchValue}
          onSearchChange={setSearch}
          data={data}
          loading={loading}
          error={error}
          reducer={reducer}
          withSelect
          onKeyUp={onKeyUp}
        />
        <FlexContainer>
          <CategoryButton
            active={tabState === 1}
            onClick={() => {
              setTabState(1);
            }}
          >
            {categoryOne}
          </CategoryButton>
          <CategoryButton
            active={tabState === 2}
            onClick={() => {
              setTabState(2);
            }}
          >
            {categoryTwo}
          </CategoryButton>
          <CategoryButton
            active={tabState === 3}
            onClick={() => {
              setTabState(3);
            }}
          >
            {categoryThree}
          </CategoryButton>
          {currentUser.type === "user" &&
          location.pathname === "/dashboard/users" ? (
            <CategoryButton
              active={tabState === 5}
              onClick={() => {
                setTabState(5);
              }}
            >
              {categoryFour}
            </CategoryButton>
          ) : null}
        </FlexContainer>
      </WrapperContainer>
    </Container>
  );
};

export default SearchWithTabs;

export const Container = styled.div`
  display: flex;
  margin: 1rem 2rem;
  flex-direction: column;
  gap: 1rem;
  background: rgba(234, 234, 243, 0.32);
  border: 0.75px solid rgba(57, 70, 115, 0.3);
  box-sizing: border-box;
  border-radius: 10px;
  padding: 1.5rem;
  @media (max-width: 1368px) {
    padding: 0.25rem;
    margin: 0.75rem;
  }
`;

export const WrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0rem 2rem;
  @media (max-width: 1368px) {
    margin: 1rem;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: space-evenly;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

export const CategoryButton = styled.button`
  outline: none;
  background: ${(props) => (props.active ? "#2F3A60" : "#EAEAF3")};
  border: 1px solid rgba(44, 40, 88, 0.07);
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  color: ${(props) => (props.active ? "#ffffff" : "#2c2858")};
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 5px;
  padding: 0.3rem 1rem;
  width: 100%;
  transition: all 200ms;
  box-sizing: border-box;
  &:active {
    transform: translateY(5px);
  }
`;
