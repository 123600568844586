import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Heading,
  LargeHeading,
  SubHeading,
  Text,
} from "../../components/shared/Typography/Typo";
import Row from "../../components/shared/core/Card/Row";
import UserVerifiedCard from "../../components/shared/core/Card/UserVerifiedCard";
import { useSelector } from "react-redux";
import * as encryption from "../../utils/encryption";
import { retrieveUsersVerifications } from "../../api/Api";
import { LoadingRow } from "../Dashboard/Dashboard";
import MoreFieldsModal from "../../components/shared/Modal/MoreFieldsModal";
import { CustomButton } from "../../components/shared/fields/Button/CustomButton";
import AnonymousImage from "../../assets/images/anonymous.png";

const UserDetails = ({ data }) => {
  const currentUser = useSelector((state) => state.currentUser);
  const [decryptedData, setDecryptedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [verification, setVerification] = useState([]);
  const [moreData, setMoreData] = useState([]);
  const [openMoreFieldsModal, setOpenMoreFieldsModal] = useState(false);

  // TODO: Can be generalized into a util function
  const decryptUserData = () => {
    const userEncryptedData = currentUser.userOwnedData;
    delete userEncryptedData.updatedAt;
    delete userEncryptedData.createdAt;
    const userSecretKey = currentUser.secretKey;
    let symmetricEncryptedData = {};
    for (let [key, value] of Object.entries(userEncryptedData)) {
      if (
        key !== "brcImage" &&
        key !== "profileImage" &&
        key !== "logo" &&
        key !== "birthCertificate" &&
        key !== "drivingLicense" &&
        key !== "marriageCertificate" &&
        key !== "signature" &&
        key !== "managerSignature" &&
        key !== "passportImage" &&
        key !== "addressImage"
      ) {
        symmetricEncryptedData[key] = encryption.symmetricDecryption(
          value.data,
          userSecretKey
        );
      } else {
        const tempArr = [];
        userEncryptedData[key]?.map((item, key) => {
          const enc = encryption.symmetricDecryption(item.data, userSecretKey);
          tempArr.push(enc);
        });
        symmetricEncryptedData[key] = tempArr;
      }
    }
    // Set state
    setDecryptedData(symmetricEncryptedData);
  };

  const retrieveVerifyApi = async () => {
    setLoading(true);
    try {
      const response = await retrieveUsersVerifications({
        userPk: currentUser.publicKey,
      });
      if (response.data.code === 200) {
        setVerification(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    decryptUserData();
    retrieveVerifyApi();
  }, []);

  return (
    <Container>
      <Heading color="#394673">Personal Details</Heading>
      <CreditBalCard>
        <FlexContainer>
          <img
            src={decryptedData?.profileImage || AnonymousImage}
            alt="profile"
            style={{
              maxHeight: "200px",
              maxWidth: "fit-content",
            }}
          />
          {/* <SubHeading primary color="#394673">
            Credit Balance
          </SubHeading> 
          <Text primary>Available user's credit balance</Text> */}
        </FlexContainer>
        {/* <CreditContainer>
          <LargeHeading primary style={{ fontSize: "4rem" }}>
            {`${data && data.creditBalance}$`}
          </LargeHeading>
        </CreditContainer> */}
      </CreditBalCard>
      {data && !loading ? (
        <>
          <MoreFieldsModal
            openMoreFieldsModal={openMoreFieldsModal}
            setOpenMoreFieldsModal={setOpenMoreFieldsModal}
            data={moreData}
            type="user"
          />

          <UserDetailsContainer>
            <Row rowName="Alias" rowData={currentUser?.alias} />
            {/* <Row rowName="Public Key" rowData={currentUser?.publicKey} /> */}
            {decryptedData?.firstName || decryptedData?.lastName ? (
              <Row
                rowName="User Name"
                rowData={`${decryptedData?.firstName || ""} ${
                  decryptedData?.lastName || ""
                }`}
              />
            ) : null}
            {decryptedData?.city || decryptedData?.country ? (
              <Row
                rowName="Address"
                rowData={`${decryptedData?.city || ""} ${
                  decryptedData?.country || ""
                }`}
              />
            ) : null}
            {decryptedData?.email ? (
              <Row rowName="Email Address" rowData={decryptedData?.email} />
            ) : null}
            {decryptedData?.nic ? (
              <Row rowName="NIC" rowData={decryptedData?.nic} />
            ) : null}
            {decryptedData?.phoneNumber ? (
              <Row
                rowName="Phone Number"
                rowData={decryptedData?.phoneNumber}
              />
            ) : null}
            <CustomButton
              tab
              text="View More"
              onclick={() => {
                setMoreData(decryptedData);
                setOpenMoreFieldsModal(true);
              }}
            />
          </UserDetailsContainer>
        </>
      ) : (
        <>
          <LoadingRow />
          <LoadingRow />
          <LoadingRow />
          <LoadingRow />
        </>
      )}
      {verification.length !== 0 && (
        <>
          <Heading color="#394673" style={{ marginTop: "2rem" }}>
            Verified By
          </Heading>
          {loading ? (
            <>
              <LoadingRow />
            </>
          ) : (
            <VerifiedByContainer>
              {verification?.map((item, key) => {
                return (
                  <UserVerifiedCard
                    key={key}
                    merchantName={item.merchantAlias}
                    status="Verified"
                  />
                );
              })}
            </VerifiedByContainer>
          )}
        </>
      )}
    </Container>
  );
};

export default UserDetails;

const Container = styled.div`
  margin: 1.8rem;
  display: flex;
  flex-direction: column;
  @media (max-width: 1368px) {
    margin: 1rem;
  }
`;

const UserDetailsContainer = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CreditContainer = styled.div`
  margin-top: 1rem;
`;

const CreditBalCard = styled.div`
  background: #eaeaf3;
  border-radius: 10px;
  border: 1px solid #3946734d;
  margin-top: 2rem;
  padding: 2rem;
  display: flex;
  justify-content: center;
  @media (max-width: 1368px) {
    padding: 1rem;
    flex-direction: column;
  }
`;

const VerifiedByContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
  @media (max-width: 1368px) {
    grid-template-columns: 1fr 1fr;
  }
`;
