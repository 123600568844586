import styled from "styled-components";
import { Content } from "../../Banners/MainBanner";
import { SubHeading, Text } from "../../Typography/Typo";

const DashBoardCard = ({ heading, description, count }) => {
  return (
    <Container>
      <Content>
        <SubHeading primary>{heading}</SubHeading>
        <Text small primary>
          {description}
        </Text>
      </Content>
      <Count>{count}</Count>
    </Container>
  );
};

export default DashBoardCard;

const Container = styled.div`
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  background: #eaeaf3;
  border: 1px solid rgba(57, 70, 115, 0.3);
  box-sizing: border-box;
  gap: 2rem;
  border-radius: 10px;
`;

const Count = styled.h1`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  margin: 1rem 0rem;
  font-size: 72px;
  line-height: 98px;
  color: #2f3a60;
  @media (max-width: 768px) {
    font-size: 54px;
  }
`;
