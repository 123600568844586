import { useState } from "react";
import styled from "styled-components";
import ArrowSVG from "../../assets/images/Arrow 1.svg";
import PersonalDetails from "./PersonalDetails";
import ProfilePreview from "./ProfilePreview";
import VerifyDetails from "./VerifyDetails";
import { useSelector } from "react-redux";

// country select options
const countryOptions = [
  { value: "LK", label: "Sri Lanka" },
  { value: "IN", label: "India" },
  { value: "USA", label: "United States" },
  { value: "UK", label: "United Kingdom" },
  { value: "SING", label: "Singapore" },
  { value: "MALAY", label: "Malaysia" },
];
// gender select options
const genderOptions = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
];
// account type select options
const accountTypeOptions = [
  { value: "savings", label: "Savings Account" },
  { value: "current", label: "Current Account" },
];
// business nature select options
const businessNatureOptions = [
  { value: "private", label: "Private Sector" },
  { value: "public", label: "Public Sector" },
];
// currency code select options
const currencyCodeOptions = [
  { value: "LKR", label: "Sri Lankan Rupee" },
  { value: "INR", label: "Indian Rupee" },
  { value: "USD", label: "US Dollar" },
  { value: "GBP", label: "Sterling Pound" },
];
// marital status select options
const maritalStatusOptions = [
  { value: "single", label: "Single" },
  { value: "married", label: "Married" },
];
// residential status select options
const residentialStatusOptions = [
  { value: "local", label: "Local" },
  { value: "foreign", label: "Foreign" },
];

const FormView = () => {
  const currentUser = useSelector((state) => state.currentUser);
  const [formStep, setFormStep] = useState(currentUser.userOwnedData ? 3 : 1);

  //personal details states
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [otherNames, setOtherNames] = useState("");
  const [maidenName, setMaidenName] = useState("");
  const [nameWithInitials, setNameWithInitials] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(new Date());
  const [nicIssueDate, setNicIssueDate] = useState(new Date());
  const [placeOfBirth, setPlaceOfBirth] = useState("");
  const [nationality, setNationality] = useState("");
  const [occupation, setOccupation] = useState("");
  const [gender, setGender] = useState(genderOptions[0]);
  const [currencyCode, setCurrencyCode] = useState(currencyCodeOptions[0]);
  const [addressL1, setAddressL1] = useState("");
  const [addressL2, setAddressL2] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState(countryOptions[0]);
  const [employerAddress, setEmployerAddress] = useState("");
  const [incomeSource, setIncomeSource] = useState("");
  const [expectedAnnualIncome, setExpectedAnnualIncome] = useState("");
  const [accountType, setAccountType] = useState(accountTypeOptions[0]);
  const [businessNature, setBusinessNature] = useState(
    businessNatureOptions[0]
  );
  const [postalCode, setPostalCode] = useState("");
  const [branchNumber, setBranchNumber] = useState("");
  const [passportIssueCountry, setPassportIssueCountry] = useState(
    countryOptions[0]
  );
  const [phoneNumber, setPhoneNumber] = useState("");
  const [faxNumber, setFaxNumber] = useState("");
  const [cifNumber, setCifNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [nic, setNic] = useState("");
  const [nicError, setNicError] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [purpose, setPurpose] = useState("");
  const [employerName, setEmployerName] = useState("");
  const [principalAccount, setPrincipalAccount] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [foreignAddress, setForeignAddress] = useState("");
  const [countryOfBirth, setCountryOfBirth] = useState(countryOptions[0]);
  const [ckycNumber, setCkycNumber] = useState("");
  const [maritalStatus, setMaritalStatus] = useState(maritalStatusOptions[0]);
  const [bankName, setBankName] = useState("");
  const [branch, setBranch] = useState("");
  const [folioNumber, setFolioNumber] = useState("");
  const [residentialStatus, setResidentialStatus] = useState(
    residentialStatusOptions[0]
  );
  const [profileImageBase64, setProfileImageBase64] = useState([]);
  const [birthCertificateBase64, setBirthCertificateBase64] = useState([]);
  const [drivingLicenseBase64, setDrivingLicenseBase64] = useState([]);
  const [marriageCertificateBase64, setMarriageCertificateBase64] = useState(
    []
  );
  const [signatureBase64, setSignatureBase64] = useState([]);
  const [managerSignatureBase64, setManagerSignatureBase64] = useState([]);

  const checkIfEmpty = () => {
    let bool = false;
    if (
      firstName !== "" &&
      lastName !== "" &&
      otherNames !== "" &&
      maidenName !== "" &&
      nameWithInitials !== "" &&
      dateOfBirth !== "" &&
      nicIssueDate !== "" &&
      placeOfBirth !== "" &&
      nationality !== "" &&
      occupation !== "" &&
      gender !== "" &&
      addressL1 !== "" &&
      addressL2 !== "" &&
      city !== "" &&
      country !== "" &&
      postalCode !== "" &&
      cifNumber !== "" &&
      phoneNumber !== "" &&
      faxNumber !== "" &&
      branchNumber !== "" &&
      purpose !== "" &&
      employerName !== "" &&
      currencyCode !== "" &&
      employerAddress !== "" &&
      incomeSource !== "" &&
      expectedAnnualIncome !== "" &&
      nic !== "" &&
      email !== "" &&
      businessNature !== "" &&
      accountType !== "" &&
      principalAccount !== "" &&
      panNumber !== "" &&
      foreignAddress !== "" &&
      countryOfBirth !== "" &&
      ckycNumber !== "" &&
      maritalStatus !== "" &&
      bankName !== "" &&
      branch !== "" &&
      folioNumber !== "" &&
      residentialStatus !== "" &&
      profileImageBase64.length !== 0 &&
      birthCertificateBase64.length !== 0 &&
      drivingLicenseBase64.length !== 0 &&
      marriageCertificateBase64.length !== 0 &&
      signatureBase64.length !== 0 &&
      managerSignatureBase64.length !== 0
    ) {
      bool = false;
    } else {
      bool = true;
    }
    return bool;
  };

  return (
    <Container disable={formStep === 3}>
      {formStep === 1 || formStep === 2 ? (
        <HeadingContainer>
          <HeadingButton
            onClick={() => {
              setFormStep(1);
              setProfileImageBase64([]);
              setBirthCertificateBase64([]);
              setDrivingLicenseBase64([]);
              setMarriageCertificateBase64([]);
              setSignatureBase64([]);
              setManagerSignatureBase64([]);
            }}
            style={{ cursor: "pointer" }}
            lighter
            color={`${formStep === 1 ? "#2f3a60" : "#2F3A606E"}`}
          >
            Add Personal Details
          </HeadingButton>
          <Arrow src={ArrowSVG} />
          <HeadingButton
            onClick={() => {
              if (!checkIfEmpty()) {
                setFormStep(2);
              }
            }}
            style={{ cursor: `${!checkIfEmpty() ? "pointer" : "unset"}` }}
            lighter
            color={`${formStep === 2 ? "#2f3a60" : "#2F3A606E"}`}
          >
            Verify Personal Details
          </HeadingButton>
        </HeadingContainer>
      ) : null}

      {(() => {
        switch (formStep) {
          case 1:
            return (
              <PersonalDetails
                firstName={firstName}
                setFirstName={setFirstName}
                lastName={lastName}
                setLastName={setLastName}
                otherNames={otherNames}
                setOtherNames={setOtherNames}
                maidenName={maidenName}
                setMaidenName={setMaidenName}
                nameWithInitials={nameWithInitials}
                setNameWithInitials={setNameWithInitials}
                dateOfBirth={dateOfBirth}
                setDateOfBirth={setDateOfBirth}
                nicIssueDate={nicIssueDate}
                setNicIssueDate={setNicIssueDate}
                placeOfBirth={placeOfBirth}
                setPlaceOfBirth={setPlaceOfBirth}
                nationality={nationality}
                setNationality={setNationality}
                occupation={occupation}
                setOccupation={setOccupation}
                gender={gender}
                setGender={setGender}
                addressL1={addressL1}
                setAddressL1={setAddressL1}
                addressL2={addressL2}
                setAddressL2={setAddressL2}
                city={city}
                setCity={setCity}
                country={country}
                setCountry={setCountry}
                postalCode={postalCode}
                setPostalCode={setPostalCode}
                passportIssueCountry={passportIssueCountry}
                setPassportIssueCountry={setPassportIssueCountry}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                faxNumber={faxNumber}
                setFaxNumber={setFaxNumber}
                phoneNumberError={phoneNumberError}
                setPhoneNumberError={setPhoneNumberError}
                email={email}
                setEmail={setEmail}
                emailError={emailError}
                setEmailError={setEmailError}
                nic={nic}
                setNic={setNic}
                nicError={nicError}
                setNicError={setNicError}
                purpose={purpose}
                setPurpose={setPurpose}
                employerName={employerName}
                setEmployerName={setEmployerName}
                currencyCode={currencyCode}
                setCurrencyCode={setCurrencyCode}
                employerAddress={employerAddress}
                setEmployerAddress={setEmployerAddress}
                incomeSource={incomeSource}
                setIncomeSource={setIncomeSource}
                cifNumber={cifNumber}
                setCifNumber={setCifNumber}
                expectedAnnualIncome={expectedAnnualIncome}
                setExpectedAnnualIncome={setExpectedAnnualIncome}
                principalAccount={principalAccount}
                setPrincipalAccount={setPrincipalAccount}
                panNumber={panNumber}
                setPanNumber={setPanNumber}
                foreignAddress={foreignAddress}
                setForeignAddress={setForeignAddress}
                countryOfBirth={countryOfBirth}
                setCountryOfBirth={setCountryOfBirth}
                ckycNumber={ckycNumber}
                setCkycNumber={setCkycNumber}
                maritalStatus={maritalStatus}
                setMaritalStatus={setMaritalStatus}
                bankName={bankName}
                setBankName={setBankName}
                branch={branch}
                setBranch={setBranch}
                folioNumber={folioNumber}
                setFolioNumber={setFolioNumber}
                residentialStatus={residentialStatus}
                setResidentialStatus={setResidentialStatus}
                profileImageBase64={profileImageBase64}
                setProfileImageBase64={setProfileImageBase64}
                birthCertificateBase64={birthCertificateBase64}
                setBirthCertificateBase64={setBirthCertificateBase64}
                drivingLicenseBase64={drivingLicenseBase64}
                setDrivingLicenseBase64={setDrivingLicenseBase64}
                marriageCertificateBase64={marriageCertificateBase64}
                setMarriageCertificateBase64={setMarriageCertificateBase64}
                signatureBase64={signatureBase64}
                setSignatureBase64={setSignatureBase64}
                managerSignatureBase64={managerSignatureBase64}
                setManagerSignatureBase64={setManagerSignatureBase64}
                branchNumber={branchNumber}
                setBranchNumber={setBranchNumber}
                businessNature={businessNature}
                setBusinessNature={setBusinessNature}
                accountType={accountType}
                setAccountType={setAccountType}
                setFormStep={setFormStep}
              />
            );
          case 2:
            return <VerifyDetails setFormStep={setFormStep} />;
          case 3:
            return <ProfilePreview />;
          default:
            return <ProfilePreview />;
        }
      })()}
    </Container>
  );
};

export default FormView;

export const Container = styled.div`
  display: ${(props) => (props.disable ? "unset" : "flex")};
  margin: ${(props) => (props.disable ? "unset" : "1rem 2rem")};
  flex-direction: ${(props) => (props.disable ? "unset" : "column")};
  gap: ${(props) => (props.disable ? "unset" : "1rem")};
  background: ${(props) =>
    props.disable ? "unset" : "rgba(234, 234, 243, 0.32);"};
  border: ${(props) =>
    props.disable ? "unset" : "0.75px solid rgba(57, 70, 115, 0.3)"};
  box-sizing: ${(props) => (props.disable ? "unset" : "border-box")};
  border-radius: ${(props) => (props.disable ? "unset" : "10px")};
  padding: ${(props) => (props.disable ? "unset" : "1.5rem")};
  @media (max-width: 1368px) {
    padding: ${(props) => (props.disable ? "unset" : "1rem")};
    margin: ${(props) => (props.disable ? "unset" : "1rem")};
  }
`;

const HeadingContainer = styled.div`
  display: flex;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  @media (max-width: 1024px) {
    background-color: #eaeaf3;
    flex-direction: column;
    align-items: center;
  }
`;

const HeadingButton = styled.h1`
  border: none;
  border-radius: 5px;
  text-align: center;
  padding: 0.5rem 2rem;
  font-family: "Open Sans", sans-serif;
  font-size: 2rem;
  font-weight: ${(props) => (props.lighter ? "500" : "700")};
  color: ${(props) => (props.primary ? "#2F3A60" : props.color)};
  @media (max-width: 1024px) {
    font-size: 1.3rem;
    padding: 1rem 0rem;
  }
`;

const Arrow = styled.img`
  position: relative;
  width: 10rem;
  height: auto;
  @media (max-width: 1024px) {
    transform: rotate(90deg);
    width: 4rem;
    height: 4rem;
  }
`;
