import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Heading, Text } from "../../components/shared/Typography/Typo";
import { LoginForm } from "./LoginForm";

export const LoginComponent = ({ isMerchant }) => {
  return (
    <Container>
      <HeadingWrapper>
        <Heading style={{ color: "#23C99E" }}>Welcome</Heading>
        {isMerchant ? (
          <Text lighter small>
            Login as a Corporate or an Individual
          </Text>
        ) : (
          <Text lighter small>
            Login as a Corporate or an Individual
          </Text>
        )}
      </HeadingWrapper>
      <LoginForm />
      <Text lighter small>
        Don't have an account?
        <Link to="/sign-up" style={{ color: "#fff" }}>
          {" "}
          SIGN UP
        </Link>
      </Text>
    </Container>
  );
};

const Container = styled.div`
  color: #fff;
  background: linear-gradient(180deg, #2f3a60 0%, #1c2338 100%);
  flex-direction: column;
  border-radius: 10px;
  padding: 2rem 2rem;
  display: flex;
  gap: 3rem;
  align-items: center;
  margin: 1.5rem;
  @media (max-width: 1368px) {
    margin: 2rem;
  }
  @media (max-width: 768px) {
    background: linear-gradient(180deg, #2f3a60c1 0%, #1c2338 100%);
  }
`;

const HeadingWrapper = styled.div`
  text-align: center;
`;
