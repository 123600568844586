import React, { useState } from "react";
import styled from "styled-components";
import { Label, TextField } from "../fields/TextField/TextField";
import { useSnackbar } from "notistack";
import { Button, useMediaQuery } from "@mui/material";
import uuid from "react-uuid";
import { useStateValue } from "../../../context/StateProvider";
import { DateComponent } from "../Date/DateComponent";
import Select from "react-select";
import { SubHeading } from "../Typography/Typo";

// gender select options
const genderOptions = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
];

const BlockForm = () => {
  const [{ fields }, dispatch] = useStateValue();
  const matches = useMediaQuery("(max-width:1368px)");
  const { enqueueSnackbar } = useSnackbar();
  const [childName, setChildName] = useState("");
  const [childDob, setChildDob] = useState("");
  const [childGender, setChildGender] = useState("");

  const handleChildFieldSubmit = async (e) => {
    e.preventDefault();
    if (childDob === "" || childName === "" || childGender === "") {
      enqueueSnackbar(`Please input all fields for child details`, {
        variant: "error",
      });
    } else {
      try {
        dispatch({
          type: "ADD_CHILD_FIELDS",
          item: {
            id: uuid(),
            childName: childName,
            childDob: new Date(childDob).valueOf(),
            childGender: childGender.value,
          },
        });
        enqueueSnackbar("Child Added", {
          variant: "success",
        });
      } catch (error) {
        console.log(error);
      } finally {
        setChildName("");
        setChildDob("");
        setChildGender("");
      }
    }
  };

  return (
    <OuterContainer>
      <SubHeading primary style={{ marginBottom: "0.5rem" }}>
        Add Child Data - Optional
      </SubHeading>
      <ContainerForm id="childForm" name="childForm">
        <TextField
          label="Name"
          placeholder="Name"
          id="childName"
          name="childName"
          type="text"
          value={childName}
          onChange={setChildName}
          color="#FF613F"
          maxLength="50"
        />
        <DateComponent
          label="Date of Birth"
          value={childDob}
          onChange={(date) => setChildDob(date)}
        />
        <ColumnContainer>
          <Label>Gender</Label>
          <Select
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                outline: "none",
                text: "orangered",
                primary25: "#2f3a6039",
                primary: " #2f3a60a7",
                neutral0: "#eaeaf3",
                boxShadow: "none",
              },
            })}
            placeholder="Select Gender"
            value={childGender}
            onChange={setChildGender}
            options={genderOptions}
          />
        </ColumnContainer>
        <ButtonWrapper style={{ girdColumn: `${!matches ? "1/3" : "unset"}` }}>
          <Button
            style={{
              background: "#1fc36157",
              color: "#080036",
              textTransform: "inherit",
              fontWeight: "500",
              fontFamily: "Poppins",
              padding: "0.3rem 1rem",
              float: "right",
            }}
            form="childForm"
            onClick={handleChildFieldSubmit}
            size="small"
          >
            Add Entry
          </Button>
        </ButtonWrapper>
      </ContainerForm>
    </OuterContainer>
  );
};

export default BlockForm;

const OuterContainer = styled.div`
  padding: 1.2rem;
  border-radius: 10px;
  background-color: #c3d0e181;
  margin: 1.5rem 0rem;
`;

const ContainerForm = styled.div`
  display: grid;
  row-gap: 1rem;
  column-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ButtonWrapper = styled.div`
  padding-top: 0.5rem;
  display: flex;
  grid-column: 1/3;
  justify-content: flex-end;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
`;
