import styled from "styled-components";
import MainBanner from "../../components/shared/Banners/MainBanner";
import BannerOne from "../../assets/images/banner-1.png";
import Content from "./Content";

const Settings = () => {
  return (
    <Container>
      <MainBanner
        heading="Settings"
        description="Access application settings here"
        image={BannerOne}
      />
      <Content />
    </Container>
  );
};

export default Settings;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
`;
