import styled from "styled-components";
import Searching from "../../assets/images/searching.svg";
import Like from "../../assets/images/like.svg";
import Timeline from "../../assets/images/timeline.svg";
import { Text } from "../../components/shared/Typography/Typo";

const Banner = () => {
  return (
    <FlexContainer>
      <ImageWrapper>
        <ImageContainer>
          <Image src={Searching} />
        </ImageContainer>
        <ImageContent>
          <Text color="#2F3A60" style={{ fontWeight: "600" }}>
            Search
          </Text>
          <Text color="#686868" lighter small>
            Search for merchants or users from the list
          </Text>
        </ImageContent>
      </ImageWrapper>
      <ImageWrapper>
        <ImageContainer>
          <Image src={Like} />
        </ImageContainer>
        <ImageContent>
          <Text color="#2F3A60" style={{ fontWeight: "600" }}>
            Select Duration
          </Text>
          <Text color="#686868" lighter small>
            Specify time duration
          </Text>
        </ImageContent>
      </ImageWrapper>
      <ImageWrapper>
        <ImageContainer>
          <Image src={Timeline} />
        </ImageContainer>
        <ImageContent>
          <Text color="#2F3A60" style={{ fontWeight: "600" }}>
            Approve
          </Text>
          <Text color="#686868" lighter small>
            Approve selected merchant
          </Text>
        </ImageContent>
      </ImageWrapper>
    </FlexContainer>
  );
};

export default Banner;

const FlexContainer = styled.div`
  margin: 8rem 0rem;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-evenly;
  @media (max-width: 1568px) {
    flex-direction: column;
    margin: 3rem 0rem;
  }
`;

const ImageContainer = styled.div`
  width: 10rem;
  padding: 1rem;
  display: grid;
  place-items: center;
  height: 10rem;
  border-radius: 50%;
  background-color: #fff;
`;

const ImageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 0.2rem;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;
