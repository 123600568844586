import { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import ArrowSVG from "../../assets/images/Arrow 1.svg";
import CorporateDetails from "./CorporateDetails";
import CorporateProfilePreview from "./CorporateProfilePreview";
import CorporateVerifyDetails from "./CorporateVerifyDetails";

// country select options
const options = [
  { value: "LK", label: "Sri Lanka" },
  { value: "IN", label: "India" },
  { value: "USA", label: "United States" },
  { value: "UK", label: "United Kingdom" },
  { value: "SING", label: "Singapore" },
  { value: "MALAY", label: "Malaysia" },
];

const CorporateFormView = () => {
  const currentUser = useSelector((state) => state.currentUser);
  const [formStep, setFormStep] = useState(currentUser.userOwnedData ? 3 : 1);

  //corporate details states
  const [startDate, setStartDate] = useState(new Date());
  const [companyName, setCompanyName] = useState("");
  const [brcNo, setBrcNo] = useState("");
  const [addressL1, setAddressL1] = useState("");
  const [addressL2, setAddressL2] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState(options[0]);
  const [employeeCount, setEmployeeCount] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [logoImageBase64, setLogoImageBase64] = useState([]);

  const checkIfEmpty = () => {
    let bool = false;
    if (
      startDate != "" &&
      companyName != "" &&
      brcNo != "" &&
      addressL1 != "" &&
      addressL2 != "" &&
      city != "" &&
      country != "" &&
      employeeCount != "" &&
      email != "" &&
      phoneNumber != "" &&
      logoImageBase64.length != 0
    ) {
      bool = false;
    } else {
      bool = true;
    }
    return bool;
  };

  return (
    <Container disable={formStep === 3}>
      {formStep === 1 || formStep === 2 ? (
        <HeadingContainer>
          <HeadingButton
            onClick={() => {
              setFormStep(1);
              setLogoImageBase64([]);
            }}
            style={{ cursor: "pointer" }}
            lighter
            color={`${formStep === 1 ? "#2f3a60" : "#2F3A606E"}`}
          >
            Add Corporate Details
          </HeadingButton>
          <Arrow src={ArrowSVG} />
          <HeadingButton
            onClick={() => {
              if (!checkIfEmpty()) {
                setFormStep(2);
              }
            }}
            style={{ cursor: `${!checkIfEmpty() ? "pointer" : "unset"}` }}
            lighter
            color={`${formStep === 2 ? "#2f3a60" : "#2F3A606E"}`}
          >
            Verify Corporate Details
          </HeadingButton>
        </HeadingContainer>
      ) : null}

      {(() => {
        switch (formStep) {
          case 1:
            return (
              <CorporateDetails
                startDate={startDate}
                setStartDate={setStartDate}
                companyName={companyName}
                setCompanyName={setCompanyName}
                addressL1={addressL1}
                setAddressL1={setAddressL1}
                addressL2={addressL2}
                setAddressL2={setAddressL2}
                city={city}
                setCity={setCity}
                country={country}
                setCountry={setCountry}
                brcNo={brcNo}
                setBrcNo={setBrcNo}
                employeeCount={employeeCount}
                setEmployeeCount={setEmployeeCount}
                email={email}
                setEmail={setEmail}
                emailError={emailError}
                setEmailError={setEmailError}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                phoneNumberError={phoneNumberError}
                setPhoneNumberError={setPhoneNumberError}
                logoImageBase64={logoImageBase64}
                setLogoImageBase64={setLogoImageBase64}
                setFormStep={setFormStep}
              />
            );
          case 2:
            return <CorporateVerifyDetails setFormStep={setFormStep} />;
          case 3:
            return <CorporateProfilePreview />;
          default:
            return <CorporateProfilePreview />;
        }
      })()}
    </Container>
  );
};

export default CorporateFormView;

export const Container = styled.div`
  display: ${(props) => (props.disable ? "unset" : "flex")};
  margin: ${(props) => (props.disable ? "unset" : "1rem 2rem")};
  flex-direction: ${(props) => (props.disable ? "unset" : "column")};
  gap: ${(props) => (props.disable ? "unset" : "1rem")};
  background: ${(props) =>
    props.disable ? "unset" : "rgba(234, 234, 243, 0.32);"};
  border: ${(props) =>
    props.disable ? "unset" : "0.75px solid rgba(57, 70, 115, 0.3)"};
  box-sizing: ${(props) => (props.disable ? "unset" : "border-box")};
  border-radius: ${(props) => (props.disable ? "unset" : "10px")};
  padding: ${(props) => (props.disable ? "unset" : "1.5rem")};
  @media (max-width: 1368px) {
    padding: ${(props) => (props.disable ? "unset" : "1rem")};
    margin: ${(props) => (props.disable ? "unset" : "1rem")};
  }
`;

const HeadingContainer = styled.div`
  display: flex;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  @media (max-width: 1024px) {
    background-color: #eaeaf3;
    flex-direction: column;
    align-items: center;
  }
`;

const HeadingButton = styled.h1`
  border: none;
  border-radius: 5px;
  text-align: center;
  padding: 0.5rem 2rem;
  font-family: "Open Sans", sans-serif;
  font-size: 2rem;
  font-weight: ${(props) => (props.lighter ? "500" : "700")};
  color: ${(props) => (props.primary ? "#2F3A60" : props.color)};
  @media (max-width: 1024px) {
    font-size: 1.3rem;
    padding: 1rem 0rem;
  }
`;

const Arrow = styled.img`
  position: relative;
  width: 10rem;
  height: auto;
  @media (max-width: 1024px) {
    transform: rotate(90deg);
    width: 4rem;
    height: 4rem;
  }
`;
