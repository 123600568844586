import styled from "styled-components";
import NexentryLogo from "../../../../assets/images/logo 1.png";
import { Text } from "../../Typography/Typo";
const CorporateVerifiedCard = ({ merchantName, status }) => {
  return (
    <Container>
      <ImageWrapper image={NexentryLogo} />
      <FlexContainer>
        <Text primary>{merchantName}</Text>
        <Text small color="#62626287">
          {status}
        </Text>
      </FlexContainer>
    </Container>
  );
};

export default CorporateVerifiedCard;

const Container = styled.div`
  width: 80%%;
  display: flex;
  background: #eaeaf3;
  justify-content: space-between;
  border-radius: 5px;
  padding: 1rem 1rem;
  margin-top: 1rem;
`;

const ImageWrapper = styled.div`
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 3rem;
  width: 12rem;
  border-radius: 5px;
  margin-right: 0.5rem;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
