import { Fragment, useState } from "react";
import styled from "styled-components";
import { CustomButton } from "../../fields/Button/CustomButton";
import { MobileImageWrapper, Name } from "../../Styles/SharedStyles";
import { Avatar } from "@mui/material";
// import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { Heading, Text } from "../../Typography/Typo";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
// import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
// import { reviewConsent } from "../../../../api/Api";
import Select from "react-select";
import { Label } from "../../fields/TextField/CustomTextField";

const options = [
  { value: 1, label: "06 Months" },
  { value: 2, label: "12 Months" },
  { value: 3, label: "24 Months" },
];

const RowCard = ({
  type,
  name,
  description,
  image,
  onClick,
  onPress,
  id,
  publicKey,
  isSelected,
}) => {
  const [selectOptions, setSelectOptions] = useState(options[0]);
  return (
    <Container onClick={onClick}>
      <Avatar variant="rounded" sx={{ width: 50, height: 50 }}>
        {name.charAt(0).toUpperCase()}
      </Avatar>
      <ColumnContainer>
        <ContentWrapper>
          <Name color="#394673">{name}</Name>
          <Text small color="#626262b3">
            {type === 5 ? `Referred By: ${description}` : description}
          </Text>
        </ContentWrapper>
        <GridContainer>
          <MobileImageWrapper image={image} />
          <div>
            <Name color="#394673">{name}</Name>
            <Text small color="#626262b3">
              {type === 5 ? `Referred By: ${description}` : description}
            </Text>
          </div>
        </GridContainer>
        {type === 5 && (
          <Fragment>
            <Label>Select Duration</Label>
            <Select
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  outline: "none",
                  text: "orangered",
                  primary25: "#2f3a6039",
                  primary: " #2f3a60a7",
                  neutral0: "#EAEAF3",
                  boxShadow: "none",
                },
              })}
              defaultValue={options[0]}
              options={options}
              onChange={setSelectOptions}
            />
            <ButtonWrapper justifyContent={"flex-end"}>
              <CustomButton
                icon={<AddCircleIcon />}
                tab
                className="successTab"
                text="Approve"
                onclick={() => {
                  onPress({
                    status: "approve",
                    id: id,
                    publicKey: publicKey,
                    validity: selectOptions.value,
                  });
                }}
              />
              <CustomButton
                icon={<DeleteIcon />}
                className="dangerTab"
                tab
                text="Remove"
                warning
                onclick={() => {
                  onPress({
                    status: "reject",
                    id: id,
                    publicKey: publicKey,
                  });
                }}
              />
            </ButtonWrapper>
          </Fragment>
        )}
        {/* <ButtonWrapper justifyContent={"flex-end"}>
          {(() => {
            switch (type) {
              case 1:
                if (isSelected === "Rejected") {
                  return (
                    <CustomButton
                      disabled={true}
                      className="dangerTab"
                      tab
                      text="Rejected"
                      warning
                    />
                  );
                }
                return (
                  <CustomButton
                    // icon={<DeleteIcon />}
                    className="dangerTab"
                    tab
                    text="Remove"
                    warning
                    onclick={() => {
                      onPress({
                        status: "reject",
                        id: id,
                        publicKey: publicKey,
                      });
                    }}
                  />
                );
              case 2:
                if (isSelected === "Approved") {
                  return (
                    <CustomButton
                      disabled={true}
                      tab
                      className="successTab"
                      text="Approved"
                    />
                  );
                } else if (isSelected === "Rejected") {
                  return (
                    <CustomButton
                      disabled={true}
                      className="dangerTab"
                      tab
                      text="Rejected"
                      warning
                    />
                  );
                }
                return (
                  <>
                    <CustomButton
                      // icon={<AddCircleIcon />}
                      tab
                      className="successTab"
                      text="Approve"
                      onclick={() => {
                        onPress({
                          status: "approve",
                          id: id,
                          publicKey: publicKey,
                        });
                      }}
                    />
                    <CustomButton
                      // icon={<DeleteIcon />}
                      className="dangerTab"
                      tab
                      text="Remove"
                      warning
                      onclick={() => {
                        onPress({
                          status: "reject",
                          id: id,
                          publicKey: publicKey,
                        });
                      }}
                    />
                  </>
                );
              case 3:
                return null;
              default:
                return (
                  <CustomButton
                    // icon={<AddCircleIcon />}
                    tab
                    className="successTab"
                    text="Approve"
                  />
                );
            }
          })()}
        </ButtonWrapper> */}
      </ColumnContainer>
    </Container>
  );
};

export default RowCard;

const Container = styled.div`
  display: flex;
  cursor: pointer;
  margin: 1rem 0rem;
  padding: 1rem;
  gap: 1rem;
  background: #eaeaf3;
  margin-right: 0.6rem;
  border-radius: 10px;
  &:hover {
    background: rgba(57, 70, 115, 0.19);
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 0.8rem;
  margin-top: 1rem;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const GridContainer = styled.div`
  display: none;
  @media (max-width: 1024px) {
    display: block;
    display: grid;
    gap: 0.5rem;
    grid-template-columns: 1fr 5fr;
  }
`;
