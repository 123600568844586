import styled from "styled-components";
import { useStateValue } from "../../../../context/StateProvider";
import { Text } from "../../Typography/Typo";
import AnonymousImage from "../../../../assets/images/anonymous.png";

const SearchRow = ({ image, data, setSearch, reducer }) => {
  const [{ search }, dispatch] = useStateValue();

  var clickId = Math.floor(Math.random() * 1000000000);

  const handleClick = () => {
    try {
      dispatch({
        type: `ADD_${reducer}`,
        payload: data,
      });
      dispatch({
        type: `SET_CLICK_ID_${reducer}`,
        payload: clickId,
      });
      setSearch("");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <FlexContainer onClick={handleClick}>
      <ImageContainer>
        <ImageWrapper>
          <Image src={image || AnonymousImage} alt="logo" />
        </ImageWrapper>
        <Text small color="#394673">
          {data?.alias}
        </Text>
      </ImageContainer>
      <Text small color="#394673">
        {data?.type === "user" ? "User" : "Merchant"}
      </Text>
      <Text small color="#394673">
        {data?.city}
      </Text>
    </FlexContainer>
  );
};

export default SearchRow;

const FlexContainer = styled.div`
  text-align: center;
  background: #eaeaf3;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  border-radius: 5px;
  margin: 0.5rem 1rem;
  display: grid;
  cursor: pointer;
  padding: 0.5rem 1rem;
  gap: 1rem;
  &:hover {
    background: #39467352;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const ImageWrapper = styled.div`
  box-sizing: border-box;
  display: grid;
  place-items: center;
  overflow: hidden;
  border-radius: 5px;
`;

const Image = styled.img`
  width: 1.5rem;
  border: 5px;
  height: 1.5rem;
`;
