import { useParams } from "react-router-dom";
import Settings from "../../../pages/Settings/Settings";
import Profile from "../../../pages/Profile/Profile";
import Users from "../../../pages/Users/Users";
import Dashboard from "../../../pages/Dashboard/Dashboard";
import Corporates from "../../../pages/Corporates/Corporates";

const UserLayout = () => {
  const { tab } = useParams();
  return (
    <div>
      {(() => {
        switch (tab) {
          case "settings":
            return <Settings />;
          case "profile":
            return <Profile />;
          case "users":
            return <Users />;
          case "corporates":
            return <Corporates />;
          default:
            return <Dashboard />;
        }
      })()}
    </div>
  );
};

export default UserLayout;
