import React, { useState } from "react";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";
import CancelIcon from "@mui/icons-material/Cancel";
import { Text } from "../../Typography/Typo";
import { AddImageToIPFS } from "../../../../utils/ipfs";

export default function DropzoneComponent(props) {
  const setProp = async (image) => {
    try {
      const ipfsData = await AddImageToIPFS(image);
      const imageURI = `https://niftron.infura-ipfs.io/ipfs/${ipfsData.ipfsHash}`;
      //check whether single image or multiple image
      if (props.amount === "1") {
        props.setFiles([imageURI]);
      } else if (props.amount === "2") {
        props.setFiles((oldValue) => [...oldValue, imageURI]);
      }
    } catch (error) {
      console.log("Error in uploading image");
    }
  };
  const [errors, setErrors] = useState("");
  const [value, setValue] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: props.type,
    maxFiles: props.amount,
    maxSize: props.maxSize,
    multiple: props.multiple,
    onDrop: (acceptedFiles, rejectedFiles) => {
      props.setFiles([]);
      rejectedFiles.forEach((fileCheck) => {
        fileCheck.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            setErrors(` Error: File is larger than 1 MB `);
          } else if (err.code === "file-invalid-type") {
            setErrors(` Error: ${err.message}`);
          } else if (err.code === "too-many-files") {
            setErrors(` Error: ${err.message}`);
          }
        });
      });
      if (rejectedFiles?.length === 0) {
        acceptedFiles.forEach((element) => {
          setValue(
            acceptedFiles?.map((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              })
            )
          );
          setProp(element);
        });
      }
    },
  });

  const thumbsValue =
    value.length > 0 &&
    value?.map((file) => (
      <div style={thumb} key={file.name}>
        <CancelIcon
          onClick={() => {
            setValue([]);
            props.setFiles([]);
          }}
          style={{
            position: "absolute",
            top: "0%",
            left: "100%",
            zIndex: "99",
            transform: "translate(-50%, -50%)",
            color: "#8a8888",
          }}
        />
        <div style={thumbInner}>
          <img alt="preview" src={file.preview} style={img} />
        </div>
      </div>
    ));

  return (
    <DroppableContainer>
      <div {...getRootProps()}>
        <input id={props.id} required={props.required} {...getInputProps()} />
        {value?.length === 0 && (
          <Text primary small>
            {errors ? errors : props.description}
          </Text>
        )}
        <aside style={thumbsContainer}>{thumbsValue}</aside>
      </div>
    </DroppableContainer>
  );
}

const DroppableContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  align-items: center;
  justify-content: space-evenly;
`;

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #EAEAEA",
  marginBottom: 8,
  marginRight: 8,
  width: "auto",
  height: 100,
  padding: 4,
  boxSizing: "border-box",
  position: "relative",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};
