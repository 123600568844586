import styled from "styled-components";

export const Heading = styled.h1`
  font-family: "Open Sans", sans-serif;
  font-size: ${(props) => (props.main ? "3rem" : "2rem")};
  font-weight: ${(props) => (props.lighter ? "500" : "700")};
  color: ${(props) => (props.primary ? "#2F3A60" : props.color)};
  @media (max-width: 768px) {
    font-size: ${(props) => (props.main ? "2rem" : "1.3rem")};
  }
`;

export const Text = styled.p`
  font-family: "Open Sans", sans-serif;
  font-size: ${(props) => (props.small ? "0.875rem" : "16px")};
  color: ${(props) => (props.primary ? "#2F3A60" : props.color)};
  font-weight: ${(props) => (props.lighter ? "300" : "500")};
  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;

export const SubHeading = styled.p`
  font-size: 1.5rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  color: ${(props) => (props.primary ? "#2F3A60" : "#ffffff")};
  @media (max-width: 768px) {
    font-size: 1.3rem;
  }
`;

export const LargeHeading = styled.h1`
  font-family: "Open Sans", sans-serif;
  font-size: 2.75rem;
  margin: 0;
  font-weight: ${(props) => (props.lighter ? "500" : "700")};
  color: ${(props) => (props.primary ? "#2F3A60" : "#fff")};
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;
