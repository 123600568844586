import { useEffect, useState } from "react";
import styled from "styled-components";
import MainBanner from "../../components/shared/Banners/MainBanner";
import BannerOne from "../../assets/images/banner-1.svg";
import DashBoardCard from "../../components/shared/core/Card/DashBoardCard";
import Table from "../../components/shared/Table/Table";
import Search from "../../components/shared/fields/Search/Search";
import { Heading, Text } from "../../components/shared/Typography/Typo";
import { retrieveConsents } from "../../api/Api";
import { useSelector } from "react-redux";
import { LoadingRow } from "../../pages/Dashboard/Dashboard";

const theadData = ["Updated", "Name", "Status", "Type"];

const CorporateDashboard = () => {
  let componentMounted = true; // (3) component is mounted
  const currentUser = useSelector((state) => state.currentUser);
  // states
  const [loading, setLoading] = useState(false);
  const [consents, setConsents] = useState([]);
  const [filterConsents, setFilterConsents] = useState([]);
  const [search, setSearch] = useState("");

  // api
  const retrieveConsentsAPI = async () => {
    const data = {
      token: localStorage.niftoken,
      query: { type: "merchant-interaction" },
    };
    setLoading(true);
    const consentsAPI = await retrieveConsents(data);
    if (componentMounted) {
      if (consentsAPI) {
        setConsents(consentsAPI.data.data);
      }
      setLoading(false);
    }
  };

  // TODO: Use backend search. Might break when pagination
  const filterSearch = () => {
    if (search !== "") {
      let tempConsents = [];
      consents?.map((item, key) => {
        if (
          item.requestedByAlias.toLowerCase().includes(search.toLowerCase()) ||
          item.requestedToAlias.toLowerCase().includes(search.toLowerCase())
        ) {
          tempConsents.push(item);
        }
      });
      setFilterConsents(tempConsents);
    }
  };

  useEffect(() => {
    retrieveConsentsAPI();
    return () => {
      // This code runs when component is unmounted
      componentMounted = false; // (4) set it to false if we leave the page
    };
  }, []);

  useEffect(() => {
    filterSearch();
  }, [search]);

  return (
    <Container>
      <MainBanner
        heading="Corporate Dashboard"
        description="Here the overview of all your consents are visible"
        image={BannerOne}
      />
      <GridContainer>
        <DashBoardCard
          heading="Pending Cases"
          description="All pending consents"
          count={currentUser?.pendingConsents}
        />
        <DashBoardCard
          heading="Rejected Cases"
          description="All rejected consents"
          count={
            currentUser?.totalConsents -
            (currentUser?.acceptedConsents + currentUser?.pendingConsents)
          }
        />
        <DashBoardCard
          heading="Approved Cases"
          description="All approved consents"
          count={currentUser?.acceptedConsents}
        />
      </GridContainer>
      <FlexContainer>
        <Heading color="#2F3A60">My Individual Cases</Heading>
        <Search
          searchValue={search}
          onSearchChange={setSearch}
          half
          placeholder="Search Cases"
        />
      </FlexContainer>
      <TableContainer>
        <TableWrapper>
          {loading ? (
            <>
              <LoadingRow />
              <LoadingRow />
              <LoadingRow />
              <LoadingRow />
            </>
          ) : consents?.length > 0 ? (
            <Table
              theadData={theadData}
              tbodyData={search !== "" ? filterConsents : consents}
              search={search}
            />
          ) : (
            <Text style={{ marginTop: "1rem" }} primary>
              No consents found
            </Text>
          )}
        </TableWrapper>
      </TableContainer>
    </Container>
  );
};

export default CorporateDashboard;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const GridContainer = styled.div`
  margin: 1rem 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-evenly;
  gap: 1rem;
  @media (max-width: 1368px) {
    grid-template-columns: 1fr;
    margin: 1rem;
  }
`;

export const TableWrapper = styled.div`
  overflow: auto;
  min-height: 30vh;
  margin: 1rem 0rem;
  padding: 0rem 2rem;
  max-height: 50vh;
`;

export const TableContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 3rem;
  margin-left: 2rem;
  margin-right: 2rem;
  padding-right: 1rem;
  background: #eaeaf3;
  border: 1px solid rgba(57, 70, 115, 0.3);
  box-sizing: border-box;
  border-radius: 10px;
  @media (max-width: 1368px) {
    margin: 1rem;
  }
`;

const FlexContainer = styled.div`
  margin-top: 3rem;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 1rem;
  gap: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1368px) {
    margin-top: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
`;
