import { ADD_USER_DETAILS } from "../constants/ActionTypes";
import { ADD_FORM_DATA } from "../constants/ActionTypes";
// import {
//     niftronUserLambda, niftronTokenLambda,
//     StellarUrl, StellarUrlTest, Version,
//     NiftronIssuerPublicKey,
//     NiftronDistributorPublicKey
// } from "variables/constants"

const initialState = {
  // niftronUserLambda, niftronTokenLambda,
  // StellarUrl, StellarUrlTest, Version,
  // NiftronIssuerPublicKey,
  // NiftronDistributorPublicKey, LoadingMessage: "please remain patient"
  currentUser: [],
  kycData: [],
};

export default function variables(state = initialState, action) {
  switch (action.type) {
    // case ADD_STELLAR_URL:
    //     state.StellarUrl = action.text
    //     return state
    case ADD_USER_DETAILS:
      state.currentUser = action.payload;
      return state;
    case ADD_FORM_DATA:
      state.kycData = action.payload;
      return state;
    default:
      return state;
  }
}
