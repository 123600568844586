import { useState } from "react";
import styled from "styled-components";
import { Container as TabContainer } from "../../components/shared/fields/Search/SearchWithTabs";
import UserDetails from "./CorporateUserDetails";

const CorporateProfilePreview = () => {
  return (
    <Container>
      <TabContainer>
        {(() => {
          return (
            <UserDetails
              data={{
                rowName: "Row Name",
                rowData: "Row Data",
                creditBalance: "1.56",
              }}
            />
          );
        })()}
      </TabContainer>
    </Container>
  );
};

export default CorporateProfilePreview;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
