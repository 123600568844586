import styled from "styled-components";
import { LargeHeading, Text } from "../Typography/Typo";

const MainBanner = ({ heading, description, image }) => {
  return (
    <FlexContainer>
      <Content>
        <LargeHeading>{heading}</LargeHeading>
        <Text lighter color="#fff">
          {description}
        </Text>
      </Content>
      <ImageContainer src={image} />
    </FlexContainer>
  );
};

export default MainBanner;

const FlexContainer = styled.div`
  display: flex;
  margin: 1rem 2rem;
  height: 8rem;
  background: linear-gradient(270deg, #1c2338 0%, #2f3a60 100%);
  border-radius: 10px;
  gap: 0.5rem;
  justify-content: space-between;
  padding: 1.5rem;
  @media (max-width: 1368px) {
    padding: 2rem;
    margin: 1rem;
    height: auto;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageContainer = styled.img`
  width: 8rem;
  height: auto;
`;
