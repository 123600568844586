import styled from "styled-components";

export const GridContainer = styled.div`
  width: 100%;
  z-index: 1;
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 2rem;
  @media (max-width: 1568px) {
    grid-template-columns: 2fr 1fr;
  }
  @media (max-width: 1368px) {
    grid-template-columns: 2fr 1.5fr;
  }
  @media (max-width: 1068px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
