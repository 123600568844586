import React, { useEffect } from "react";

import { Route, Switch } from "react-router";
import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import SignUp from "./pages/SignUp/SignUp";
import styled from "styled-components";
import DashboardLayout from "./components/layouts/MainLayout/DashboardLayout";
import GuardedRoute from "react-guarded-route";
import { NIFTRON } from "niftron-client-sdk";
import jwt from "jsonwebtoken";
import { ADD_USER_DETAILS } from "./redux/constants/ActionTypes";
import store from "./redux/reducers";
import { useState } from "react";

import { LoadingView } from "./LoadingView";
import axios from "axios";
import { useSnackbar } from "notistack";

const rax = require("retry-axios");
require("dotenv").config();

const AUTH_URL = process.env.REACT_APP_AUTH_URL;
const niftronConfig = {
  projectKey: process.env.REACT_APP_PROJECT_KEY,
  secretKey: process.env.REACT_APP_DEV_SECRET_KEY,
};
export const Niftron = new NIFTRON(niftronConfig);

rax.attach();
Niftron.initialize();

const authValidator = () => {
  if (localStorage.getItem("niftoken")) {
    const token = localStorage.getItem("niftoken");
    if (token) {
      const decodedToken = jwt.decode(token);
      if (decodedToken == null) {
        localStorage.removeItem("secretKey");
        localStorage.removeItem("niftoken");
        return false;
      } else if (
        new Date(decodedToken.exp).toISOString() > new Date().toISOString()
      ) {
        localStorage.removeItem("secretKey");
        localStorage.removeItem("niftoken");
        return false;
      } else {
        return true;
      }
    }
  }
};

export default function App() {
  let componentMounted = true; // (3) component is mounted
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setError] = useState("Loading");

  async function decodeUser(data) {
    try {
      const response = await axios.get(AUTH_URL + "me", {
        headers: {
          "x-auth-token": data.token,
        },
        raxConfig: data.config,
      });
      return response;
    } catch (error) {
      if (error) {
        enqueueSnackbar("Couldn't retrieve user", {
          variant: "error",
        });
        setTimeout(() => {
          NIFTRON.user.logout();
          localStorage.removeItem("secretKey");
        }, 1000);
        console.error(error);
      }
    }
  }

  const retrieveUser = async () => {
    const token = localStorage.getItem("niftoken");
    const retryConfig = {
      retry: 5, // number of retry when facing 4xx or 5xx
      noResponseRetries: 5, // number of retry when facing connection error
      onRetryAttempt: (err) => {
        const cfg = rax.getConfig(err);
        setError(
          `Couldn't retrieve user, Retry attempt #${cfg.currentRetryAttempt}`
        );
        console.log(
          `Couldn't retrieve user, Retry attempt #${cfg.currentRetryAttempt}`
        ); // track current trial
      },
    };

    setLoading(true);
    try {
      const retrieveUserDetails = await decodeUser({
        token: token,
        config: retryConfig,
      });
      if (componentMounted) {
        if (retrieveUserDetails && retrieveUserDetails.data) {
          store.dispatch({
            type: ADD_USER_DETAILS,
            payload: retrieveUserDetails.data.data[0],
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (localStorage.getItem("niftoken")) {
      retrieveUser();
      return () => {
        // This code runs when component is unmounted
        componentMounted = false; // (4) set it to false if we leave the page
      };
    }
  }, []);

  return (
    <Container>
      {loading ? (
        <LoadingView message={errorMessage} />
      ) : (
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/sign-up" component={SignUp} />
          <Route exact path="/" component={Home} />
          {/* <Route path="/dashboard/:tab" component={DashboardLayout} /> */}
          {/* <Route path="/dashboard" component={DashboardLayout} /> */}
          <GuardedRoute
            path="/dashboard/:tab"
            component={DashboardLayout}
            redirectTo="/" //(Optional) Redirect to '/login' if validatorFunction returns false. Will redirect to '/' if not provided.
            validatorFunction={authValidator()}
          />
          <GuardedRoute
            path="/dashboard"
            component={DashboardLayout}
            redirectTo="/login" //(Optional) Redirect to '/login' if validatorFunction returns false. Will redirect to '/' if not provided.
            validatorFunction={authValidator()}
          />
        </Switch>
      )}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
`;
