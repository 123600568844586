import React from "react";
import styled from "styled-components";
import { Text } from "../Typography/Typo";

const NotificationBox = ({ image, title, time }) => {
  return (
    <Container>
      <ImageWrapper image={image} />
      <ColumnContainer>
        <Text
          style={{
            display: "inline-block",
            width: "100%",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
          primary
        >
          {title}
        </Text>
        <Text small color="#707070">
          {time}
        </Text>
      </ColumnContainer>
    </Container>
  );
};

export default NotificationBox;

const Container = styled.div`
  display: flex;
  gap: 1rem;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 5px;
  &:nth-child(odd) {
    background-color: #464f6d1f;
  }
  &:hover {
    background-color: #464f6d52;
  }
`;

const ImageWrapper = styled.div`
  background-color: #fff;
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 3rem;
  min-width: 3rem;
  height: 3rem;
  width: 3rem;
  border-radius: 5px;
`;

const ColumnContainer = styled.div`
  min-width: 0;
  display: flex;
  flex-direction: column;
`;
