import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  margin: 1rem 2rem;
  margin-bottom: 3rem;
  flex-direction: column;
  gap: 1rem;
  background: rgba(234, 234, 243, 0.32);
  border: 0.75px solid rgba(57, 70, 115, 0.3);
  box-sizing: border-box;
  border-radius: 10px;
  padding: 1.5rem;
  @media (max-width: 1024px) {
    padding: 0.25rem;
    margin: 0.75rem;
  }
`;

export const WrapperContainer = styled.div`
  margin: 0rem 1rem;
  @media (max-width: 1024px) {
    margin: 1rem;
  }
`;

export const ViewLayout = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

export const Line = styled.span`
  height: 75vh;
  width: 0.7px;
  background: rgba(57, 70, 115, 0.42);
  @media (max-width: 1024px) {
    width: 40vw;
    height: 0.7px;
  }
`;

export const SearchResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.5rem 1rem;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const ImageWrapper = styled.div`
  background-color: #fff;
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: auto;
  width: 6rem;
  border-radius: 5px;
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const MobileImageWrapper = styled.div`
  display: none;
  @media (max-width: 1024px) {
    display: block;
    background-color: #fff;
    background-image: url(${(props) => props.image});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: auto;
    width: 4rem;
    border-radius: 5px;
  }
`;

export const ScrollContainer = styled.div`
  min-height: 40rem;
  max-height: 40rem;
  overflow: auto;
`;

export const ScrollWrapper = styled.div`
  position: relative;
  margin-top: 1rem;
  min-width: 26rem;
  @media (max-width: 768px) {
    min-width: unset;
  }
`;

export const Name = styled.h1`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 33px;
  text-transform: capitalize;
  color: ${(props) => (props.primary ? "#2F3A60" : props.color)};
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;
