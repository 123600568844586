import React from "react";
import styled from "styled-components";
import { Text } from "../../Typography/Typo";

function Row({ rowName, rowData }) {
  return (
    <Container>
      <Text color="#2F3A60">{rowName}</Text>
      <Text color="#2F3A60">{rowData || "N/A"}</Text>
    </Container>
  );
}

export default Row;

const Container = styled.div`
  border-radius: 5px;
  background: #eaeaf3;
  padding: 1rem 2rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 1368px) {
    place-items: center;
    padding: 1rem;
  }
`;
