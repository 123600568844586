import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { USER_FIELDS, MERCHANT_FIELDS } from "../../../utils/fields";
import { Checkbox } from "@mui/material";
import { Text } from "../../shared/Typography/Typo";
import CustomTextField from "../fields/TextField/CustomTextField";
import { useSelector } from "react-redux";
import styled from "styled-components";

const FieldSelectionModal = ({
  type,
  openSelectionModal,
  setFieldSelections,
  setShowAction,
  userData,
}) => {
  const currentUser = useSelector((state) => state.currentUser);
  const initialArray =
    currentUser && currentUser.type === "user"
      ? USER_FIELDS.map((field) => ({ ...field, value: false }))
      : MERCHANT_FIELDS.map((field) => ({ ...field, value: false }));
  const [open, setOpen] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [selected, setSelected] = useState(initialArray);
  const [selectAll, setSelectAll] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    setFieldSelections(selected.filter((val) => val.value === true));
    setShowAction(true);
    setOpen(false);
  };

  const toggleSelectAll = () => {
    setFieldSelections(selected.map((val) => (val.value = !selectAll)));
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    setOpen(openSelectionModal);
  }, [openSelectionModal]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>
        <h3>Select Fields</h3>
        <DialogContentText marginBottom={"0.5em"}>
          Select the fields you wish to share/request details
        </DialogContentText>
        <CustomTextField
          label="Filter"
          placeholder="Filter"
          id="filter"
          name="filter"
          type="text"
          value={filterText}
          required
          onChange={setFilterText}
          color="#FF613F"
        />
        <CheckBoxContainer>
          <Checkbox
            checked={selectAll ? true : false}
            name="selectall"
            onChange={toggleSelectAll}
          />
          <Text primary>Select All</Text>
        </CheckBoxContainer>
      </DialogTitle>
      <DialogContent>
        {selected.map((val) => (
          <div
            style={{
              padding: "0.25em",
              display: val.label
                .toLowerCase()
                .includes(filterText.toLowerCase())
                ? "flex"
                : "none",
            }}
            onClick={() => {
              const cpy = selected.map((sel) => {
                return val.name === sel.name
                  ? { ...sel, value: !sel.value }
                  : sel;
              });
              setSelected(cpy);
            }}
          >
            <Text color="#626262b3">
              <Checkbox checked={val.value} />
              {val.label}
            </Text>
          </div>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FieldSelectionModal;

const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
`;
