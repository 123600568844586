import { useState } from "react";
import styled from "styled-components";
import { searchMerchant } from "../../api/Api";
import SearchWithTabs from "../../components/shared/fields/Search/SearchWithTabs";
import CorporatesContainer from "./CorporatesContainer";

const Corporates = () => {
  const [searchInput, setSearchInput] = useState("");
  const [tabView, setTabView] = useState(0);
  const [merchants, setMerchants] = useState([]);

  // Loading state
  const [searchLoading, setSearchLoading] = useState(false);

  //Check Values
  const [searchError, setSearchError] = useState(false);

  //Search match check
  const SearchMatch = async (value) => {
    setSearchLoading(true);
    const merchantData = await searchMerchant(value);
    setSearchLoading(false);

    if (merchantData) {
      setSearchError(false);
      setMerchants(merchantData.data.data);
    } else if (merchantData === undefined) {
      setSearchError(true);
    }
  };

  const handleInputChange = (value) => {
    setSearchInput(value);
    // if (value?.length > 2) {
    // if (value?.length % 2 === 0) {
    SearchMatch(value);
    // }
    // }
  };

  // const handleKeyUp = (value) => {
  //   if (value?.length > 3) {
  //     if (value?.length % 2 === 0) {
  //       SearchMatch(value);
  //     }
  //   } else if (!value) {
  //   }
  // };

  return (
    <Container>
      <SearchWithTabs
        loading={searchLoading}
        error={searchError}
        placeholder="Search Corporates"
        tabState={tabView}
        setSearch={handleInputChange}
        // onKeyUp={handleKeyUp}
        setTabState={setTabView}
        categoryOne="Added Corporates"
        categoryTwo="Corporate Requests"
        categoryThree="Transactions"
        data={merchants}
        searchValue={searchInput}
        reducer="MERCHANTS"
      />
      <CorporatesContainer view={tabView} setView={setTabView} />
    </Container>
  );
};

export default Corporates;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const FlexContainer = styled.div`
  margin: 1rem 2rem;
  gap: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1368px) {
    margin: 1rem;
  }
`;
