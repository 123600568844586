import styled from "styled-components";
import Test from "../../../../assets/images/text logo.png";
import { Text } from "../../Typography/Typo";
import { Avatar } from "@mui/material";

const UserVerifiedCard = ({ merchantName, status }) => {
  return (
    <Container>
      <Avatar variant="rounded" sx={{ width: "3rem", height: "3rem" }}>
        {merchantName.charAt(0).toUpperCase()}
      </Avatar>
      {/* <ImageWrapper image={Test} /> */}
      <FlexContainer>
        <Text primary>{merchantName}</Text>
        <Text small color="#62626287">
          {status}
        </Text>
      </FlexContainer>
    </Container>
  );
};

export default UserVerifiedCard;

const Container = styled.div`
  display: flex;
  background: #eaeaf3;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
`;

const ImageWrapper = styled.div`
  background-color: #fff;
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 3rem;
  width: 3rem;
  border-radius: 5px;
  margin-right: 0.5rem;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.8rem;
`;
