import { useState, useEffect } from "react";
import { Avatar, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { useHistory } from "react-router-dom";
import { NIFTRON } from "niftron-client-sdk";
import { getUserSession } from "../../../services/UserManagement";
import { useSelector } from "react-redux";
import * as encryption from "../../../utils/encryption";
import AnonymousImage from "../../../assets/images/anonymous.png";

const AccountPopUp = () => {
  let componentMounted = true;
  const currentUser = useSelector((state) => state.currentUser);
  const [user, setUser] = useState(getUserSession());
  const [decryptedData, setDecryptedData] = useState([]);
  const [userType, setUserType] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const decryptUserData = () => {
    const userEncryptedData = currentUser.userOwnedData;
    delete userEncryptedData.updatedAt;
    delete userEncryptedData.createdAt;
    const userSecretKey = currentUser.secretKey;
    let symmetricEncryptedData = {};
    if (componentMounted) {
      for (let [key, value] of Object.entries(userEncryptedData)) {
        if (
          key !== "brcImage" &&
          key !== "profileImage" &&
          key !== "logo" &&
          key !== "birthCertificate" &&
          key !== "drivingLicense" &&
          key !== "marriageCertificate" &&
          key !== "signature" &&
          key !== "managerSignature" &&
          key !== "passportImage" &&
          key !== "addressImage"
        ) {
          symmetricEncryptedData[key] = encryption.symmetricDecryption(
            value.data,
            userSecretKey
          );
        } else {
          const tempArr = [];
          userEncryptedData[key]?.map((item, key) => {
            const enc = encryption.symmetricDecryption(
              item.data,
              userSecretKey
            );
            tempArr.push(enc);
          });
          symmetricEncryptedData[key] = tempArr;
        }
      }
    }
    setDecryptedData(symmetricEncryptedData);
  };

  const checkUserType = () => {
    if (currentUser.type === "user") {
      setUserType("user");
      if (currentUser.userOwnedData != undefined) {
        decryptUserData();
      }
    } else {
      setUserType("merchant");
    }
  };

  useEffect(() => {
    checkUserType();
    return () => {
      componentMounted = false;
    };
  }, []);

  return (
    <div>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip
          title={
            currentUser &&
            user &&
            user?.alias.split("-")[1].charAt(0).toUpperCase() +
              user?.alias.split("-")[1].substring(1)
          }
        >
          <IconButton onClick={handleClick} size="small">
            <Avatar
              sx={{ width: 32, height: 32 }}
              src={
                userType === "merchant"
                  ? currentUser?.userOwnedData?.logo?.data || AnonymousImage
                  : userType === "user"
                  ? decryptedData?.profileImage || AnonymousImage
                  : null
              }
            >
              {currentUser &&
                user &&
                user?.alias.split("-")[1].charAt(0).toUpperCase()}
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => {
            history.push("/dashboard/profile");
          }}
        >
          <Avatar
            src={
              userType === "merchant"
                ? currentUser?.userOwnedData?.logo?.data || AnonymousImage
                : userType === "user"
                ? decryptedData?.profileImage || AnonymousImage
                : null
            }
          />
          {currentUser &&
            user &&
            user.alias.split("-")[1].charAt(0).toUpperCase() +
              user.alias.split("-")[1].substring(1)}
        </MenuItem>

        <Divider />
        <MenuItem>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Add another account
        </MenuItem>
        <MenuItem
          onClick={() => {
            history.push("/dashboard/settings");
          }}
        >
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem
          onClick={() => {
            NIFTRON.user.logout();
            localStorage.removeItem("secretKey");
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
};

export default AccountPopUp;
