import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Heading, Text } from "../../components/shared/Typography/Typo";
import CredentialsPage from "./CredentialsPage";
import { SignUpForm } from "./SignUpForm";

export const SignUpComponent = () => {
  const [signUpStep, setSignUpStep] = useState(0);
  const [userData, setUserData] = useState({
    alias: "",
    publicKey: "",
    secretKey: "",
  });
  return (
    <Container>
      <HeadingWrapper>
        <Heading>Welcome</Heading>
        <Text lighter small>
          Sign up to your application
        </Text>
      </HeadingWrapper>
      {signUpStep === 0 ? (
        <SignUpForm
          setSignUpStep={setSignUpStep}
          setUserData={setUserData}
          userData={userData}
        />
      ) : (
        <CredentialsPage userData={userData} />
      )}
      <Text lighter small>
        Already have an account?
        <Link to="/login" style={{ color: "#fff" }}>
          {" "}
          LOGIN
        </Link>
      </Text>
    </Container>
  );
};

export default SignUpComponent;

const Container = styled.div`
  color: #fff;
  background: linear-gradient(180deg, #2f3a60 0%, #1c2338 100%);
  flex-direction: column;
  border-radius: 10px;
  padding: 2rem 2rem;
  display: flex;
  gap: 3rem;
  align-items: center;
  margin: 1.5rem;
  @media (max-width: 1368px) {
    margin: 2rem;
  }
  @media (max-width: 768px) {
    background: linear-gradient(180deg, #2f3a60c1 0%, #1c2338 100%);
  }
`;

const HeadingWrapper = styled.div`
  text-align: center;
`;
