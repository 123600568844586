export const MORE_USER_FIELDS = [
  {
    name: "maidenName",
    label: "Maiden Name",
  },
  {
    name: "otherNames",
    label: "Other Name",
  },
  {
    name: "nameWithInitials",
    label: "Name with Initials",
  },
  {
    name: "placeOfBirth",
    label: "Place of Birth",
  },
  {
    name: "nationality",
    label: "Nationality",
  },
  {
    name: "foreignAddress",
    label: "Foreign Address",
  },
  {
    name: "birthCertificate",
    label: "Birth Certificate",
  },
  {
    name: "drivingLicense",
    label: "Driving License",
  },
  {
    name: "marriageCertificate",
    label: "Marriage Certificate",
  },
  {
    name: "signature",
    label: "Signature",
  },
  {
    name: "passportImage",
    label: "Passport Image",
  },
  {
    name: "addressImage",
    label: "Address Image",
  },
];

export const MORE_MERCHANT_FIELDS = [
  {
    name: "companyName",
    label: "Company Name",
  },
  {
    name: "brcNo",
    label: "BRC No",
  },
  {
    name: "employeeCount",
    label: "Employee Count",
  },
  {
    name: "addressL1",
    label: "Address Line 1",
  },
  {
    name: "addressL2",
    label: "Address Line 2",
  },
  {
    name: "city",
    label: "City",
  },
  {
    name: "country",
    label: "Country",
  },
  {
    name: "phoneNumber",
    label: "Phone Number",
  },
  {
    name: "email",
    label: "Email",
  },
  {
    name: "brcImage",
    label: "BRC Image",
  },
];
