import { useState } from "react";
import styled from "styled-components";
import SearchWithTabs from "../../components/shared/fields/Search/SearchWithTabs";
import CorporateUsersContainer from "./CorporateUsersContainer";
import * as API from "../../api/Api";

const CorporateUsers = () => {
  const [users, setUserData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [tabView, setTabView] = useState(0);

  // Loading state
  const [searchLoading, setSearchLoading] = useState(false);

  //Check Values
  const [searchError, setSearchError] = useState(false);

  const SearchMatch = async (value) => {
    setSearchLoading(true);
    const userData = await API.searchUser({
      query: {
        key: value,
      },
    });
    setSearchLoading(false);
    if (userData) {
      setSearchError(false);
      setUserData(userData.data.data);
    } else if (userData === undefined) {
      setSearchError(true);
    }
  };

  const handleInputChange = (value) => {
    setSearchInput(value);
    // if (value && value?.length > 1) {
    SearchMatch(value);
    // }
  };

  return (
    <Container>
      {/* <MainBanner
        heading="Users"
        description="All added and pending user requests along with transaction history can be seen here"
        image={BannerOne}
      /> */}
      <SearchWithTabs
        loading={searchLoading}
        error={searchError}
        placeholder="Search Users"
        setSearch={handleInputChange}
        searchValue={searchInput}
        tabState={tabView}
        setTabState={setTabView}
        categoryOne="Added Users"
        categoryTwo="User Requests"
        categoryThree="Transactions"
        reducer="USERS"
        data={users}
      />
      <CorporateUsersContainer view={tabView} setView={setTabView} />
    </Container>
  );
};

export default CorporateUsers;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
`;
