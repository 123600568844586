import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/index.css";
import App from "./App";
import { SnackbarProvider } from "notistack";
import { BrowserRouter as Router } from "react-router-dom";
import reducer, { initialState } from "./context/reducer";
import { StateProvider } from "./context/StateProvider";
import { Provider } from "react-redux";
import store from "./redux/reducers";

ReactDOM.render(
  <React.StrictMode>
    <SnackbarProvider
      maxSnack={1}
      preventDuplicate
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Router>
        <Provider store={store}>
          <StateProvider initialState={initialState} reducer={reducer}>
            <App />
          </StateProvider>
        </Provider>
      </Router>
    </SnackbarProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.register();
