import { Link } from "react-router-dom";
import styled from "styled-components";
import Logo from "../../assets/images/logo 1.png";
import { CustomButton } from "../../components/shared/fields/Button/CustomButton";
import { Heading } from "../../components/shared/Typography/Typo";

export const LoginBanner = ({ isMerchant }) => {
  return (
    <Container>
      <TextContainer>
        <Link to="/">
          <LogoContainer src={Logo} />
        </Link>
        <div style={{ paddingLeft: "1rem" }}>
          <Heading color="#fff">Individual and Corporate KYC</Heading>
          <Text lighter color="#fff">
            An all-in-one workflow solution to verify your customers’
            identities, streamline a KYC on-boarding process and manage the
            entire customer lifecycle.
          </Text>
        </div>
        <ButtonContainer>
          {/* {isMerchant ? (
            <CustomButton
              login
              loginIcon
              secondaryLoginClass
              text="Sign in as a Corporate"
            />
          ) : (
            <CustomButton
              login
              loginIcon
              secondaryLoginClass
              text="Sign in as an Individual"
            />
          )} */}
        </ButtonContainer>
      </TextContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 1.5rem;
  @media (max-width: 1368px) {
    margin: 0.6rem;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const LogoContainer = styled.img`
  margin-left: 0.5rem;
  width: 16rem;
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
  height: auto;
`;

const Text = styled.p`
  font-family: "Open Sans", sans-serif;
  font-size: ${(props) => (props.small ? "0.875rem" : "1.125rem")};
  width: 80%;
  margin-top: 0.5rem;
  color: ${(props) => (props.primary ? "#2C2858" : props.color)};
  font-weight: ${(props) => (props.lighter ? "300" : "bold")};
  @media (max-width: 768px) {
    font-size: 0.875rem;
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  margin: 1.5rem 1rem;
`;
