export const initialState = {
  formData: [],
  users: [],
  merchants: [],
  authConfirm: false,
  currentUser: [],
  clickIdMerchant: 0,
  clickIdUser: 0,
  fields: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "ADD_USERS":
      return {
        ...state,
        users: action.payload,
      };
    case "ADD_USER_DETAILS":
      return {
        ...state,
        currentUser: action.payload,
      };
    case "CLEAR_USERS":
      return {
        ...state,
        users: [],
      };
    case "ADD_MERCHANTS":
      return {
        ...state,
        merchants: action.payload,
      };
    case "CLEAR_MERCHANTS":
      return {
        ...state,
        merchants: [],
      };
    case "ADD_FORM_DATA":
      return {
        ...state,
        formData: action.payload,
      };
    case "CLEAR_FORM_DATA":
      return {
        ...state,
        formData: [],
      };
    case "SET_CLICK_ID_MERCHANTS":
      return {
        ...state,
        clickIdMerchant: action.payload,
      };
    case "SET_CLICK_ID_USERS":
      return {
        ...state,
        clickIdUser: action.payload,
      };
    case "ADD_CHILD_FIELDS":
      return {
        ...state,
        fields: [...state.fields, action.item],
      };
    case "REMOVE_CHILD_FIELD":
      const fieldID = state.fields.findIndex(
        (fieldsItem) => fieldsItem.id === action.id
      );
      let newFields = [...state.fields];
      if (fieldID >= 0) {
        newFields.splice(fieldID, 1);
      } else {
        console.warn(`Cannot remove the requested field`);
      }
      return {
        ...state,
        fields: newFields,
      };
    // case "ADD_PRODUCTS":
    //     return {
    //       ...state,
    //       products: action.item,
    //     };
    // case "ADD_PRODUCT":
    //   return {
    //     ...state,
    //     products: [...state.products, action.item],
    //   };
    // case "REMOVE_FIELD":
    //   const index = state.fields.findIndex(
    //     (fieldsItem) => fieldsItem.name === action.id
    //   );

    //   let newFields = [...state.fields];
    //   if (index >= 0) {
    //     newFields.splice(index, 1);
    //   } else {
    //     console.warn(`Cannot remove the requested field (id: ${action.id}`);
    //   }
    //   return {
    //     ...state,
    //     fields: newFields,
    //   };

    // case "REMOVE_PRODUCT":
    //   const key = state.products.findIndex(
    //     (productsItem) => productsItem.id === action.id
    //   );
    //   let newProducts = [...state.products];
    //   if (key >= 0) {
    //     newProducts.splice(key, 1);
    //   } else {
    //     console.warn(`Cannot remove the requested products (id: ${action.id}`);
    //   }
    //   return {
    //     ...state,
    //     products: newProducts,
    //   };

    // case "EDIT_PRODUCT":
    //   const updatedProduct = action.payload;

    //   const updatedProducts = state.products.map((product) => {
    //     if (product.productId === updatedProduct.productId) {
    //       return updatedProduct;
    //     }

    //     return product;
    //   });

    //   return {
    //     ...state,
    //     products: updatedProducts,
    //   };

    // case "GET_PRODUCT":
    //   const productId = action.id;
    //   const productWithId = state.products.map((product) => {
    //     if (product.productId === productId) {
    //       return product;
    //     }
    //   });

    //   return {
    //     ...state,
    //     product: productWithId,
    //   };

    // case "CLEAR_FIELDS":
    //   return {
    //     ...state,
    //     fields: [],
    //   };

    // case "CLEAR_PRODUCTS":
    //   return {
    //     ...state,
    //     products: [],
    //   };
    default:
      return state;
  }
};

export default reducer;
