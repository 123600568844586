import React from "react";
import styled from "styled-components";
import Test from "../../../assets/images/text logo.png";
import User from "../../../assets/images/user.jpg";
import { Text } from "../Typography/Typo";
import Select from "react-select";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { CustomButton } from "../fields/Button/CustomButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import { useSelector } from "react-redux";

const options = [
  { value: "06", label: "06 Months" },
  { value: "12", label: "12 Months" },
  { value: "18", label: "18 Months" },
];

export default function TableRow({ data, customRow, tableView, transactions }) {
  const currentUser = useSelector((state) => state.currentUser);
  return (
    <Tr>
      {customRow ? (
        (() => {
          switch (tableView) {
            case 1:
              return (
                <>
                  <MainContent>
                    <ImageWrapper image={Test} />
                    <ContentWrapper>
                      <Text small style={{ fontWeight: "bold" }} primary>
                        {data.name}
                      </Text>
                      <Text small color="#626262b3">
                        {data.category}
                      </Text>
                    </ContentWrapper>
                  </MainContent>
                  <Td style={{ minWidth: "10rem" }}>
                    <Select
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          outline: "none",
                          text: "orangered",
                          primary25: "#2f3a6039",
                          primary: " #2f3a60a7",
                          neutral0: "#eaeaf3",
                          boxShadow: "none",
                        },
                      })}
                      defaultValue={data.visibility}
                      options={options}
                    />
                  </Td>
                  <Td>
                    <GridContainer>
                      <Text small primary>
                        {data.status === 1 ? "Verified" : "Pending"}
                      </Text>
                      <CheckCircleIcon
                        style={{
                          color: `${
                            data.status === 1 ? " #23C99E" : "#f3ab59"
                          }`,
                        }}
                      />
                    </GridContainer>
                  </Td>
                  <Td style={{ minWidth: "12rem", width: "15rem" }}>
                    <CustomButton
                      icon={<ChangeCircleIcon />}
                      className="warningTab"
                      tab
                      text="Revoke Access"
                      warning
                    />
                  </Td>
                </>
              );
            case 2:
              return (
                <>
                  <MainContent>
                    <ImageWrapper image={Test} />
                    <ContentWrapper>
                      <Text small style={{ fontWeight: "bold" }} primary>
                        {data.name}
                      </Text>
                      <Text small color="#626262b3">
                        {data.category}
                      </Text>
                    </ContentWrapper>
                  </MainContent>
                  <Td style={{ minWidth: "10rem" }}>
                    <Select
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          outline: "none",
                          text: "orangered",
                          primary25: "#2f3a6039",
                          primary: " #2f3a60a7",
                          boxShadow: "none",
                        },
                      })}
                      defaultValue={{
                        value: "none",
                        label: "Select Visibility",
                      }}
                      options={options}
                    />
                  </Td>
                  <Td>
                    <ButtonWrapperGrid>
                      <CustomButton
                        icon={<AddCircleIcon />}
                        tab
                        className="successTab"
                        text="Approve"
                      />
                      <CustomButton
                        icon={<DeleteIcon />}
                        className="dangerTab"
                        tab
                        text="Remove"
                        warning
                      />
                    </ButtonWrapperGrid>
                  </Td>
                </>
              );
            case 3:
              return (
                <>
                  <MainContent>
                    <ImageWrapper image={User} />
                    <ContentWrapper style={{ alignItems: "center" }}>
                      <Text small style={{ fontWeight: "bold" }} primary>
                        {data.name}
                      </Text>
                    </ContentWrapper>
                  </MainContent>
                  <Td>
                    <GridContainer>
                      <Text small primary>
                        {data.status === 1 ? "Accepted" : "Pending"}
                      </Text>
                      <CheckCircleIcon
                        style={{
                          color: `${
                            data.status === 1 ? " #23C99E" : "#f3ab59"
                          }`,
                        }}
                      />
                    </GridContainer>
                  </Td>
                  <Td>
                    <ButtonWrapperGrid>
                      <CustomButton
                        icon={<AddCircleIcon />}
                        tab
                        className={
                          data.status === 0 ? "disabledTab" : "successTab"
                        }
                        text="Refer Merchants"
                        disabled={data.status === 0}
                      />
                      <CustomButton
                        icon={<DeleteIcon />}
                        className="dangerTab"
                        tab
                        text="Remove"
                        warning
                      />
                    </ButtonWrapperGrid>
                  </Td>
                </>
              );
            case 4:
              return (
                <>
                  <MainContent>
                    <ImageWrapper image={User} />
                    <ContentWrapper style={{ alignItems: "center" }}>
                      <Text small style={{ fontWeight: "bold" }} primary>
                        {data.name}
                      </Text>
                    </ContentWrapper>
                  </MainContent>
                  <Td>
                    <ButtonWrapperGrid style={{ minWidth: "28rem" }}>
                      <CustomButton
                        icon={<AddCircleIcon />}
                        tab
                        className="successTab"
                        text="Accept Request"
                      />
                      <CustomButton
                        icon={<DeleteIcon />}
                        className="dangerTab"
                        tab
                        text="Remove"
                        warning
                      />
                    </ButtonWrapperGrid>
                  </Td>
                </>
              );
            default:
              return (
                <>
                  <MainContent>
                    <ImageWrapper image={Test} />
                    <ContentWrapper>
                      <Text small style={{ fontWeight: "bold" }} primary>
                        {data.name}
                      </Text>
                      <Text small color="#626262b3">
                        {data.category}
                      </Text>
                    </ContentWrapper>
                  </MainContent>
                  <Td style={{ minWidth: "10rem" }}>
                    <Select
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          outline: "none",
                          text: "orangered",
                          primary25: "#2f3a6039",
                          primary: " #2f3a60a7",
                          boxShadow: "none",
                        },
                      })}
                      defaultValue={data.visibility}
                      options={options}
                    />
                  </Td>
                  <Td>
                    <GridContainer>
                      <Text small primary>
                        {data.status === 1 ? "Verified" : "Pending"}
                      </Text>
                      <CheckCircleIcon
                        style={{
                          color: `${
                            data.status === 1 ? " #23C99E" : "#f3ab59"
                          }`,
                        }}
                      />
                    </GridContainer>
                  </Td>
                  <Td style={{ minWidth: "12rem", width: "15rem" }}>
                    <CustomButton
                      icon={<ChangeCircleIcon />}
                      className="warningTab"
                      tab
                      text="Revoke Access"
                      warning
                    />
                  </Td>
                </>
              );
          }
        })()
      ) : transactions ? (
        <>
          <Td>
            <Text small primary>
              {new Date(data.date).toLocaleDateString("en-us", {
                year: "numeric",
                month: "short",
                day: "numeric",
              })}
            </Text>
          </Td>
          <Td>
            <Text small primary>
              {data.requestedByAlias}
            </Text>
          </Td>
          <Td>
            <Text small primary>
              {data.requestedToAlias}
            </Text>
          </Td>
          <Td>
            <Text small primary>
              {new Date(data.expiryDate).toLocaleDateString("en-us", {
                year: "numeric",
                month: "short",
                day: "numeric",
              })}
            </Text>
          </Td>
          <Td>
            <Text small primary>
              {data.status === 0
                ? "Pending"
                : data.status === 1
                ? "Connected"
                : "Rejected"}
            </Text>
          </Td>
          <Td>
            <Text small primary>
              {data.action === "u2u"
                ? "User"
                : currentUser.type === "merchant"
                ? "User"
                : "Merchant"}
            </Text>
          </Td>
        </>
      ) : (
        <>
          <Td>
            <Text>
              {new Date(data.date).toLocaleDateString("en-us", {
                year: "numeric",
                month: "short",
                day: "numeric",
              }) +
                " " +
                new Date(data.date).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
            </Text>
          </Td>
          <Td>
            <Text small primary>
              {data.requestedToAlias === currentUser.alias
                ? data.requestedByAlias
                : data.requestedToAlias}
            </Text>
          </Td>
          <Td>
            <Text small primary>
              {data.status === 1 ? "Connected" : "Pending"}
            </Text>
          </Td>
          <Td>
            <Text small primary>
              {data.action === "u2u"
                ? "User"
                : currentUser.type === "merchant"
                ? "User"
                : "Merchant"}
            </Text>
          </Td>
        </>
      )}
    </Tr>
  );
}

const Tr = styled.tr`
  &:nth-child(odd) {
    background: rgba(57, 70, 115, 0.048);
  }
  &:hover {
    background: rgba(57, 70, 115, 0.226);
  }
`;

const Td = styled.td`
  padding: 1rem;
  &:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  &:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

const MainContent = styled.td`
  padding: 1rem;
  gap: 1rem;
  display: flex;
  min-width: 15rem;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  &:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageWrapper = styled.div`
  background-color: #fff;
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 3rem;
  width: 3rem;
  border-radius: 5px;
`;

const GridContainer = styled.div`
  display: grid;
  place-items: center;
  gap: 1rem;
  grid-template-columns: 0.5fr 1fr;
`;

const ButtonWrapperGrid = styled.div`
  min-width: 15rem;
  display: grid;
  place-items: center;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
`;
